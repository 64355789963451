<template>
    <div v-if="availableForUser" class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Исполнение бюджета</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>Бюджетные кредиты</div>
        <b-progress variant="success" v-show="progress < 100" height="10px" :value="progress" striped animated></b-progress>
        <b-tabs>
            <b-tab title="Общие сведения">
                <div id="bipFormId" class="table-container form-card">
                    <!-- Панель управления -->
                    <div class="filter-container">
                        <!-- Хлебная крошка -->
                        <div v-if="this.currentMode !== 'add'" class="filter-breadcrumb">
                            <span class="item-breadcrumb">
                                {{ mainAgreement.numberContract }}
                            </span>
                            <span class="item-breadcrumb" @click="openAdditionalsModal('open')">
                                <template v-if="NewItem.type === 0">
                                    Основной
                                </template>
                                <template v-else>
                                    {{ NewItem.numberContract }}
                                </template>
                            </span>
                        </div>
                        <div>
                            <template v-if="this.currentMode === 'add'">
                                <b-button @click="goBack">К реестру</b-button>
                            </template>
                            <template v-else-if="this.currentMode === 'add-additional'">
                                <b-button variant="danger" @click="handleOpenAdditionalContract">Отмена</b-button>
                            </template>
                            <template v-else-if="this.currentMode === 'add-lower-level'">
                                <b-button variant="danger" @click="handleOpenLowerLevelAgreement">Отмена</b-button>
                            </template>
                            <template v-else-if="this.currentMode === 'edit'">
                                <b-button variant="danger" @click="handleOpenEditMode">Отмена</b-button>
                            </template>
                            <template v-else>
                                <b-button @click="goBack">К реестру</b-button>
                                <b-button variant="success" v-if="isAdditionalAddable" @click="handleOpenAdditionalContract">
                                    Добавить дополнение
                                </b-button>
                                <b-button variant="success" v-if="isLowerLevelAddable" @click="handleOpenLowerLevelAgreement">
                                    Добавить нижестоящий договор
                                </b-button>

                                <b-button style="margin-left: auto; margin-right: 0;" @click="handleOpenEditMode" variant="primary">
                                    <div style="display: flex; align-items: center">
                                        <i class="icon icon-pencil-edit"></i>
                                        <span class="left"> Редактировать </span>
                                    </div>
                                </b-button>

                                <b-button v-if="NewItem.flagActual === 1" variant="danger" @click="handleDeleteAgreement">
                                    Удалить
                                </b-button>
                            </template>
                            <b-button v-if="this.currentMode !== 'view'" variant="success" size="tr" class="float-right" style="margin-left: auto;" @click="checkForm">
                                Сохранить
                            </b-button>
                        </div>
                    </div>
                    <b-row>
                        <!-- Первая колонка -->
                        <b-col cols="12" md="6">

                            <div v-for="col in keys.filter(key => key.columnOrder === 1)" :key="col.key">

                                <div v-if="col.key === 'numberContract'" class="no-line">

                                    <b-form-group class="no-line" label="№ договора">
                                        <div style="display: flex; align-items: center;">
                                            <b-form-input style="max-width: 260px; margin-right: 10px; display: inline;"
                                                v-model.trim="NewItem['numberContract']"
                                                :formatter="handleFormattedInput['numberContract']"
                                                :class="$v.NewItem['numberContract'].$error ? 'is-invalid' : ''"
                                                :disabled="isDisabled('numberContract')" type="text" />
                                            <p v-if="$v.NewItem['numberContract'].$dirty && !$v.NewItem['numberContract'].required && $v.NewItem['numberContract'].required !== undefined"
                                                class="invalid-feedback">Обязательное поле</p>
                                            <p v-if="$v.NewItem['numberContract'].$dirty && !$v.NewItem['numberContract'].requireIfBudgetLevelNotRepublican && $v.NewItem['numberContract'].requireIfBudgetLevelNotRepublican !== undefined"
                                                class="invalid-feedback">Не соответсвие с ИФ</p>

                                            <b-form-group style="padding-right: 0; width: 100%;" class="no-line" label="От">
                                                <b-form-input style="min-width: 157px;"
                                                    :value="millisToDate(NewItem['dateContract'])" type="date"
                                                    :formatter="handleDateFormat" :disabled="isDisabled('dateContract')"
                                                    :class="$v.NewItem['dateContract'].$error ? 'is-invalid' : ''"
                                                    @input="value => handleInputDate('dateContract', value)" />
                                                <p v-if="$v.NewItem['dateContract'].$dirty && !$v.NewItem['dateContract'].required && $v.NewItem['dateContract'].required !== undefined"
                                                    class="invalid-feedback">Обязательное поле</p>
                                            </b-form-group>
                                        </div>
                                    </b-form-group>

                                </div>

                                <div v-if="col.key === 'amount'" class="no-line">

                                    <b-form-group class="no-line" label="Сумма кредита">
                                        <div style="display: flex; align-items: center;">

                                            <b-form-input style="margin-right: 5px;" :value="formatDigitReadability(NewItem['amount'])"
                                                @input="value => handleInput('amount', value)"
                                                :class="$v.NewItem['amount'].$error ? 'is-invalid' : ''"
                                                :disabled="isDisabled('amount')" type="text" />
                                            <p v-if="$v.NewItem['amount'].$dirty && !$v.NewItem['amount'].required && $v.NewItem['amount'].required !== undefined"
                                                class="invalid-feedback">Обязательное поле</p>
                                            <p v-if="$v.NewItem['amount'].$dirty && !$v.NewItem['amount'].requireIfBudgetLevelNotRepublican && $v.NewItem['amount'].requireIfBudgetLevelNotRepublican !== undefined"
                                                class="invalid-feedback">Не соответсвие с ИФ</p>

                                            <multiselect v-model="NewItem['codeCurrency']"
                                                :options="asyncSelectOptions['codeCurrency']"
                                                :disabled="isDisabled('codeCurrency')" label="label"
                                                @select="selectionChange" @remove="removeElement"
                                                :class="$v.NewItem['codeCurrency'].$error ? 'is-invalid' : ''"
                                                @search-change="(query) => handleLoadSelects(keys.find(col => col.key === 'codeCurrency'), query)" />
                                            <p v-if="$v.NewItem['codeCurrency'].$dirty && !$v.NewItem['codeCurrency'].required && $v.NewItem['codeCurrency'].required !== undefined"
                                                class="invalid-feedback">Обязательное поле</p>
                                            <p v-if="$v.NewItem['codeCurrency'].$dirty && !$v.NewItem['codeCurrency'].requireIfBudgetLevelNotRepublican && $v.NewItem['codeCurrency'].requireIfBudgetLevelNotRepublican !== undefined"
                                                class="invalid-feedback">Не соответсвие с ИФ</p>

                                        </div>
                                    </b-form-group>
                                </div>

                                <b-form-group v-if="col.key === 'begDate'" class="no-line" label="Период освоения">
                                    <div style="display: flex; align-items: center;" class="no-line">
                                        <div style="width: 50%;" class="legend">
                                            <b-form-group style="padding: 0 5px 0 0;" class="no-line" label="с">
                                                <b-form-input :value="millisToDate(NewItem['begDate'])" type="date"
                                                    :formatter="handleDateFormat" :disabled="isDisabled('begDate')"
                                                    :class="$v.NewItem['begDate'].$error ? 'is-invalid' : ''"
                                                    @input="value => handleInputDate('begDate', value)" />
                                                <p v-if="$v.NewItem['begDate'].$dirty && !$v.NewItem['begDate'].required && $v.NewItem['begDate'].required !== undefined"
                                                    class="invalid-feedback">Обязательное поле</p>
                                            </b-form-group>
                                        </div>
                                        <div style="width: 50%;" class="legend">
                                            <b-form-group style="padding: 0 5px 0 0;" class="no-line" label="по">
                                                <b-form-input :value="millisToDate(NewItem['endDate'])" type="date"
                                                    :formatter="handleDateFormat" :disabled="isDisabled('endDate')"
                                                    :class="$v.NewItem['endDate'].$error ? 'is-invalid' : ''"
                                                    @input="value => handleInputDate('endDate', value)" />
                                                <p v-if="$v.NewItem['endDate'].$dirty && !$v.NewItem['endDate'].required && $v.NewItem['endDate'].required !== undefined"
                                                    class="invalid-feedback">Обязательное поле</p>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'text' && !['numberContract', 'amount'].includes(col.key)"
                                    class="no-line" :label="col.label">
                                    <b-form-input v-model.trim="NewItem[col.key]" :formatter="handleFormattedInput[col.key]"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        :disabled="isDisabled(col.key)" type="text" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].requireIfBudgetLevelNotRepublican && $v.NewItem[col.key].requireIfBudgetLevelNotRepublican !== undefined"
                                        class="invalid-feedback">Не соответсвие с ИФ</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'textarea'" class="no-line" :label="col.label">
                                    <b-form-textarea v-model.trim="NewItem[col.key]"
                                        :formatter="handleFormattedInput[col.key]"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        :disabled="isDisabled(col.key)" type="text" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].requireIfBudgetLevelNotRepublican && $v.NewItem[col.key].requireIfBudgetLevelNotRepublican !== undefined"
                                        class="invalid-feedback">Не соответсвие с ИФ</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'number'" class="no-line" :label="col.label">
                                    <b-form-input v-model="NewItem[col.key]" :formatter="handleFormattedInput[col.key]"
                                        :disabled="isDisabled(col.key)"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''" type="number" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                </b-form-group>

                                <b-form-group
                                    v-if="col.type === 'date' && !['dateContract', 'begDate', 'endDate'].includes(col.key)"
                                    :label="col.label">
                                    <b-form-input :value="millisToDate(NewItem[col.key])" type="date"
                                        :formatter="handleDateFormat" :disabled="isDisabled(col.key)"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        @input="value => handleInputDate(col.key, value)" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'select'" class="no-line" :label="col.label">
                                    <b-form-select v-model="NewItem[col.key]" :options="col.selects"
                                        :disabled="isDisabled(col.key)"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'asyncSelect' && !['codeCurrency'].includes(col.key)"
                                    class="no-line" :label="col.label">
                                    <multiselect :value="NewItem[col.key]"
                                        :options="asyncSelectOptions[col.key]" :disabled="isDisabled(col.key)" label="label"
                                        @input="value => handleSelectInput(col.key, value)" 
                                        @remove="removeElement"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        @search-change="(query) => handleLoadSelects(col, query)" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].requireIfBudgetLevelNotRepublican && $v.NewItem[col.key].requireIfBudgetLevelNotRepublican !== undefined"
                                        class="invalid-feedback">Не соответсвие с ИФ</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'radio'" class="no-line" :label="col.label">
                                    <b-form-radio-group id="radio-group-1" :disabled="isDisabled(col.key)"
                                        v-model="contractorType" @change="onContractorTypeChange" :options="col.selects" name="radio-options" />
                                </b-form-group>

                            </div>

                        </b-col>
                        <!-- Вторая колонка -->
                        <b-col cols="12" md="6">

                            <div v-for="col in keys.filter(key => key.columnOrder === 2)" :key="col.key">

                                <b-form-group v-if="col.type === 'text'" class="no-line" :label="col.label">
                                    <b-form-input v-model.trim="NewItem[col.key]" :formatter="handleFormattedInput[col.key]"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        :disabled="isDisabled(col.key)" type="text" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].requireIfBudgetLevelNotRepublican && $v.NewItem[col.key].requireIfBudgetLevelNotRepublican !== undefined"
                                        class="invalid-feedback">Не соответсвие с ИФ</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'textarea'" class="no-line" :label="col.label">
                                    <b-form-textarea v-model.trim="NewItem[col.key]"
                                        :formatter="handleFormattedInput[col.key]"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        :disabled="isDisabled(col.key)" type="text" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].requireIfBudgetLevelNotRepublican && $v.NewItem[col.key].requireIfBudgetLevelNotRepublican !== undefined"
                                        class="invalid-feedback">Не соответсвие с ИФ</p>
                                </b-form-group>

                                <div v-if="col.key === 'contractYears'" style="display: flex;">
                                    <b-form-group class="no-line" label="Срок кредита, лет">
                                        <b-form-input :value="NewItem['contractYears']"
                                            @input="handleContractYearInput"
                                            :formatter="handleFormattedInput['contractYears']"
                                            :disabled="isDisabled('contractYears')"
                                            :class="$v.NewItem['contractYears'].$error ? 'is-invalid' : ''" type="number" />
                                        <p v-if="$v.NewItem['contractYears'].$dirty && !$v.NewItem['contractYears'].required && $v.NewItem['contractYears'].required !== undefined"
                                            class="invalid-feedback">Обязательное поле</p>
                                    </b-form-group>
                                    <b-form-group class="no-line" label="Льготный период, месяцев">
                                        <b-form-input v-model="NewItem['benefitMonths']"
                                            :formatter="handleFormattedInput['benefitMonths']"
                                            :disabled="isDisabled('benefitMonths')"
                                            :class="$v.NewItem['benefitMonths'].$error ? 'is-invalid' : ''" type="number" />
                                        <p v-if="$v.NewItem['benefitMonths'].$dirty && !$v.NewItem['benefitMonths'].required && $v.NewItem['benefitMonths'].required !== undefined"
                                            class="invalid-feedback">Обязательное поле</p>
                                    </b-form-group>
                                </div>

                                <b-form-group
                                    v-if="col.type === 'number' && !['contractYears', 'benefitMonths'].includes(col.key)"
                                    class="no-line" :label="col.label">
                                    <b-form-input v-model="NewItem[col.key]" :formatter="handleFormattedInput[col.key]"
                                        :disabled="isDisabled(col.key)"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''" type="number" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'date'" :label="col.label">
                                    <b-form-input :value="millisToDate(NewItem[col.key])" type="date"
                                        :formatter="handleDateFormat" :disabled="isDisabled(col.key)"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        @input="value => handleInputDate(col.key, value)" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'select'" class="no-line" :label="col.label">
                                    <b-form-select v-model="NewItem[col.key]" :options="col.selects"
                                        :disabled="isDisabled(col.key)"
                                        :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                </b-form-group>

                                <b-form-group v-if="col.type === 'asyncSelect'" class="no-line" :label="col.label">
                                    <multiselect :value="NewItem[col.key]" :options="asyncSelectOptions[col.key]"
                                        :disabled="isDisabled(col.key)" label="label" @input="value => handleSelectInput(col.key, value)"
                                        @remove="removeElement" :class="$v.NewItem[col.key].$error ? 'is-invalid' : ''"
                                        @search-change="(query) => handleLoadSelects(col, query)" />
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].required && $v.NewItem[col.key].required !== undefined"
                                        class="invalid-feedback">Обязательное поле</p>
                                    <p v-if="$v.NewItem[col.key].$dirty && !$v.NewItem[col.key].requireIfBudgetLevelNotRepublican && $v.NewItem[col.key].requireIfBudgetLevelNotRepublican !== undefined"
                                        class="invalid-feedback">Не соответсвие с ИФ</p>
                                </b-form-group>

                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="p-0">
                            <c-file-load
                                :agreementId="this.NewItem.id"
                                :agreementNumber="this.NewItem.numberContract"
                                :reading="this.currentMode === 'view'"
                                :files="this.NewItem.files"
                                @addFile="handleAddFile"
                                @removeFile="handleRemoveFile"
                            />
                        </b-col>
                    </b-row>
                </div>
            </b-tab>
            <b-tab v-if="contractId" title="График платежей">
                <c-payment-inflow-schedule-tab :currentMode="currentMode" :mainAgreement="mainAgreement" :NewItem="NewItem" :contractId="contractId" :agreement="NewItem" :additionalAgreements="additionalContracts" :frequencyOptions="frequencyOptions" :changeModeFromAddToReadOnly="changeModeFromAddToReadOnly" @reloadFact="reloadFact" @openAdditionalsModal="openAdditionalsModal" :key="NewItem.id" />
            </b-tab>
            <b-tab v-if="contractId" title="Фактический график">
                <c-payment-schedule-fact-tab :currentMode="currentMode" :chosenAgreement="NewItem" :mainAgreement="mainAgreement" :actualAgreement="actualAgreement" :userSub="userSub" @reloadForecast="reloadForecast" @openAdditionalsModal="openAdditionalsModal" :key="actualAgreement.index" />
            </b-tab>
            <b-tab v-if="contractId" title="Прогнозный график">
                <c-payment-schedule-forecast-tab :agreementId="contractId" :currentMode="currentMode" :mainAgreement="mainAgreement" :NewItem="NewItem" @openAdditionalsModal="openAdditionalsModal" :key="NewItem.id + forecastTabKey" />
            </b-tab>
        </b-tabs>
        <loading :active="loading" is-full-screen spinner="bar-fade-scale" color="#6495ED" />
        <!-- Модалка для выбора договора -->
        <b-modal v-model="showModal" modal-class="modal-table staffing-table-modal fixed-bottom-row" title="Выбрать дополнительное соглашение" size="xl" hide-footer>
            <multiselect :value="NewItem" @input="setChosenItem" :options="additionalContracts" label="label"
                            track-by="id"
                        />
        </b-modal>
    </div>
</template>

<script>
import store from '@/services/store';
import "bootstrap-vue";
import CPaymentInflowScheduleTab from '@/modules/budget/execution/loan/tabs/PaymentInflowScheduleTab.vue'
import CPaymentScheduleFactTab from '@/modules/budget/execution/loan/tabs/PaymentScheduleFactTab.vue'
import CPaymentScheduleForecastTab from '@/modules/budget/execution/loan/tabs/PaymentScheduleForecastTab.vue'
import CFileLoad from '@/modules/budget/execution/loan/components/file-load.vue'
import { validationMixin } from 'vuelidate';
import VueElementLoading from 'vue-element-loading';
import { required, helpers, minLength, maxLength, numeric } from 'vuelidate/lib/validators';
import Multiselect from "vue-multiselect";
import { debounce } from 'lodash';
import axios from 'axios'

const defaultValues = {
    type: 0,
    flagActual: 1,
    benefitMonths: 0,
    files: []
}
const noSearchSelects = ['typeLoan']
const notEditableFields = ['dateContract', 'rate', 'frequency', 'frequencyRate', 'amount', 'codeCurrency', 'codeCurrency', 'contractYears', 'contractDateEnd']
const editableFields = ['typeLoan', 'numberContract', 'dateContract', 'noteRu', 'loanDirection', 'budgetLoanPurpose', 'codeProject', 'codeProg', 'creditor', 'codeGu', 'contractorType', 'contractor', 'amount', 'codeCurrency', 'currencyRate', 'rate', 'contractYears', 'contractDateEnd', 'benefitMonths', 'begDate', 'endDate', 'directResult', 'finalResult', 'penalty', 'frequency', 'frequencyRate', 'frequencyIncome', 'prg', 'ppr', 'file', 'statusId']
const notEditableAdditionalAgreementFields = ['typeLoan', 'numberContractMain', 'budgetLevel', 'loanDirection', 'budgetLoanPurpose', 'codeProject', 'codeProg', 'creditor', 'codeGu', 'codeCurrency']
const notEditableLowerLevelAgreementFields = ['typeLoan', 'type', 'numberContractMain', 'loanDirection', 'budgetLoanPurpose', 'codeProject', 'codeProg', 'codeCurrency']
export default {
    name: "agreement-registration-form",

    components: {
        'loading': VueElementLoading,
        multiselect: Multiselect,
        'c-payment-inflow-schedule-tab': CPaymentInflowScheduleTab,
        'c-payment-schedule-fact-tab': CPaymentScheduleFactTab,
        'c-payment-schedule-forecast-tab': CPaymentScheduleForecastTab,
        'c-file-load': CFileLoad
    },

    data() {
        return {
            availableForUser: true,
            userSub: store.state.user.sub,
            contractId: null,
            progress: 0,
            loading: false,
            showModal: false,
            /**
             * view
             * edit
             * add
             * add-additional
             * add-lower-level
             */
            currentMode: 'view',
            NewItem: defaultValues,
            tempItem: {},
            mainAgreement: {},
            actualAgreement: {},
            additionalContracts: [],
            chosenItem: {},
            asyncSelectOptions: {},
            handleFormattedInput: {},
            contractorType: 'ГУ',
            contractorTemp: {},
            frequencyOptions: [],
            idsOfFilesToDelete: [],
            forecastTabKey: 0,
            forecastSchedule: [],
            keys: [
                {
                    key: 'budgetLevel',
                    label: "Источник финансирования",
                    type: 'asyncSelect',
                    trackBy: 'id',
                    selectsUrl: "/api/dict/dict_budget_level/pages?search-text=",
                    validation: {
                        required: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'numberContract',
                    label: '№ договора',
                    type: 'text',
                    validation: {
                        required: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'dateContract',
                    label: 'Дата подписания документа',
                    type: 'date',
                    validation: {
                        required: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'numberContractMain',
                    label: "Ссылка на основной договор",
                    type: 'text',
                    columnOrder: 1
                },
                {
                    key: 'typeLoan',
                    label: 'Вид займа',
                    type: 'asyncSelect',
                    trackBy: 'code',
                    selectsUrl: "api/dict/bc_type_loan",
                    validation: {
                        required: true
                    },
                    columnOrder: 2
                },
                {
                    key: 'loanDirection',
                    label: "Направление кредита",
                    type: 'asyncSelect',
                    trackBy: 'budgetLevelId',
                    selectsUrl: "/api/dict/bc_section/pages?search-text=",
                    validation: {
                        required: true
                    },
                    columnOrder: 2
                },
                {
                    key: 'budgetLoanPurpose',
                    label: "Цель бюджетного кредитования",
                    type: 'asyncSelect',
                    trackBy: 'code',
                    selectsUrl: "/api/dict/bc_goal/active-by-date",
                    validation: {
                        required: true
                    },
                    fetchAll: true,
                    columnOrder: 2
                },
                {
                    key: 'codeProject',
                    label: 'Проект',
                    type: 'asyncSelect',
                    trackBy: 'code',
                    selectsUrl: "/api/dict/bc_project/pages/code-name?search-text=",
                    validation: {
                        required: true
                    },
                    columnOrder: 2
                },
                {
                    key: 'codeProg',
                    label: 'Гос. программа',
                    type: 'asyncSelect',
                    trackBy: 'code',
                    selectsUrl: "/api/dict/program/pages/by-name-and-server-region?name=",
                    nameBy: ['name_ru'],
                    columnOrder: 2
                },
                {
                    key: 'creditor',
                    label: 'Кредитор',
                    type: 'asyncSelect',
                    trackBy: 'code',
                    selectsUrl: "/api/dict/gu/main/pages/main-budget-level?",
                    params: {
                        'budget-level': null,
                        'search-text': ''
                    },
                    validation: {
                        required: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'codeGu',
                    label: 'Администратор',
                    type: 'asyncSelect',
                    trackBy: 'code',
                    selectsUrl: "/api/dict/gu/pages/main?search-text=",
                    validation: {
                        required: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'contractorType',
                    label: 'Тип заемщика',
                    type: 'radio',
                    selects: ['ГУ', 'Иные (по БИН-у)'],
                    columnOrder: 1
                },
                {
                    key: 'contractor',
                    label: 'Заемщик',
                    type: 'asyncSelect',
                    trackBy: 'code',
                    selectsUrl: "/api/dict/gu/pages/by-code-or-name-and-region-code?",
                    params: {
                        'region-code': "",
                        'search-text': ""
                    },
                    validation: {
                        required: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'prg',
                    label: 'Код программы',
                    type: 'asyncSelect',
                    trackBy: 'prg',
                    selectsUrl: "api/dict/ebk_func_types/pages/by-abp-and-type-and-prg?",
                    params: {
                        abp: '0',
                        type: 4,
                        'search-text': ''
                    },
                    columnOrder: 1
                },
                {
                    key: 'ppr',
                    label: 'Код подпрограммы',
                    type: 'asyncSelect',
                    trackBy: 'ppr',
                    selectsUrl: "api/dict/ebk_func_types/pages/by-abp-and-type-and-prg?",
                    params: {
                        abp: '0',
                        type: 5,
                        prg: '',
                        'search-text': ''
                    },
                    columnOrder: 1
                },
                {
                    key: 'amount',
                    label: 'Сумма кредита (в тенге)',
                    type: 'text',
                    validation: {
                        required: true,
                        numeric: true,
                        maxFloat: 2,
                        digitReadability: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'codeCurrency',
                    label: 'Валюта',
                    type: 'asyncSelect',
                    trackBy: 'code',
                    selectsUrl: "/api/dict/currency/budget-loan?search-text=",
                    validation: {
                        required: true
                    },
                    fetchAll: true,
                    columnOrder: 1
                },
                {
                    key: 'currencyRate',
                    label: "Курс валюты на дату подписания документа",
                    type: 'text',
                    validation: {
                        // required: true, // TODO: set true when not tenge
                        numeric: true,
                        maxFloat: 2,
                        digitReadability: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'contractYears',
                    label: 'Срок кредита, лет',
                    type: 'number',
                    validation: {
                        required: true,
                        whole: true
                    },
                    columnOrder: 2
                },
                {
                    key: 'contractDateEnd',
                    label: 'Дата возврата кредита',
                    type: 'date',
                    validation: {
                        required: true
                    },
                    columnOrder: 2
                },
                {
                    key: 'benefitMonths',
                    label: 'Льготный период, месяцев',
                    type: 'number',
                    validation: {
                        required: true,
                        whole: true
                    },
                    columnOrder: 2
                },
                {
                    key: 'begDate',
                    label: 'Начало периода освоения',
                    type: 'date',
                    validation: {
                        required: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'endDate',
                    label: 'Окончание периода освоения',
                    type: 'date',
                    validation: {
                        required: true
                    },
                    columnOrder: 1
                },
                {
                    key: 'directResult',
                    label: 'Прямой результат',
                    type: 'text',
                    columnOrder: 1
                },
                {
                    key: 'finalResult',
                    label: 'Конечный результат',
                    type: 'text',
                    columnOrder: 1
                },
                {
                    key: 'rate',
                    label: 'Ставка вознаграждения, %',
                    type: 'text',
                    validation: {
                        required: true,
                        numeric: true,
                        maxFloat: 3
                    },
                    columnOrder: 2
                },
                {
                    key: 'penalty',
                    label: 'Пеня за каждый день просрочки, %',
                    type: 'text',
                    validation: {
                        required: true,
                        numeric: true,
                        maxFloat: 3
                    },
                    columnOrder: 2
                },
                {
                    key: 'statusId',
                    label: "Статус",
                    type: 'asyncSelect',
                    trackBy: 'id',
                    selectsUrl: "api/dict/bc_status",
                    validation: {
                        required: true
                    },
                    columnOrder: 2
                },
                {
                    key: 'flagActual',
                    label: "Актуальность",
                    type: 'select',
                    selects: [
                        { value: 0, text: 'Неактуальный' },
                        { value: 1, text: 'Актуальный' },
                    ],
                    validation: {
                        required: true
                    },
                    columnOrder: 2
                },
                {
                    key: 'noteRu',
                    label: 'Примечание',
                    type: 'textarea',
                    columnOrder: 2
                },
                {
                    key: 'file',
                    label: 'Примечание',
                    type: 'file',
                    columnOrder: 1
                }
            ],
        };
    },

    mixins: [validationMixin],

    validations() {
        const NewItem = {}
        const formatter = {}
        for (const key in this.keys) {
            const element = this.keys[key];
            NewItem[element.key] = {}
            formatter[element.key] = value => value
            if (element.validation) {
                /* Обязательное поле */
                if (element.validation.required) {
                    NewItem[element.key].required = required
                }
                /* Минимальная длина строки */
                if (element.validation.minLength) {
                    NewItem[element.key].minLength = minLength(element.validation.minLength)
                }
                /* Максимальная длина строки */
                if (element.validation.maxLength) {
                    const maximumLength = element.validation.maxLength
                    NewItem[element.key].maxLength = maxLength(maximumLength)
                    const copiedPreviousFunc = formatter[element.key].bind({});
                    formatter[element.key] = (value) => {
                        value = this.formatMaxLength(value, maximumLength)
                        return copiedPreviousFunc(value)
                    }
                }
                /* Только числовые значения (для input-а типа text) */
                if (element.validation.numeric) {
                    const copiedPreviousFunc = formatter[element.key].bind({});
                    formatter[element.key] = value => {
                        value = this.formatOnlyNumeric(value)
                        return copiedPreviousFunc(value)
                    }
                }
                /* Ограничение плавоющей точки */
                if (element.validation.maxFloat) { // restrict if more than one ','
                    const copiedPreviousFunc = formatter[element.key].bind({});
                    formatter[element.key] = value => {
                        value = this.formatMaxFloat(value, element.validation.maxFloat)
                        return copiedPreviousFunc(value)
                    }
                }
                /* Разрядость для читабельности чисел */
                // if (element.validation.digitReadability) {
                //     const copiedPreviousFunc = formatter[element.key].bind({});
                //     formatter[element.key] = value => {
                //         value = this.formatDigitReadability(value)
                //         return copiedPreviousFunc(value)
                //     }
                // }
                /* Только целочислинные */
                if (element.validation.whole) {
                    const copiedPreviousFunc = formatter[element.key].bind({});
                    formatter[element.key] = value => {
                        value = this.formatWholeNumber(value)
                        return copiedPreviousFunc(value)
                    }
                }
                /* Проверка обязательности в зависимости от budgetLevel (01) */
                if (element.validation.requireIfBudgetLevelNotRepublican) {
                    const validate = (value) => {
                        let isValid = false
                        if (this.isRepublicanBudget && this.NewItem[element.key]) {
                            isValid = true
                        } else if (this.isRepublicanBudget) {
                            isValid = true
                        }
                        return isValid
                    }
                    NewItem[element.key].requireIfBudgetLevelNotRepublican = validate
                }
            }
        }
        this.handleFormattedInput = formatter

        this.handleFormattedInput['contractor'] = (value) => {
            const maximumLength = 12
            value = value.length > maximumLength ? value.substring(0, maximumLength) : value
            value = value.replace(/[^\d.,]/g, "");
            return value
        }

        return {
            NewItem
        }
    },

    async created() {
        if (!this.checkUserModuleAccess()) {
            this.availableForUser = false
            this.makeToast('Отсутствует доступ к режиму', 'Сообщение', 'danger')
            return
        }
        const mode = this.$route.params.mode
        const id = this.$route.params.id ? parseInt(this.$route.params.id) : null
        await this.loadAgreementByIdAndSetMode(id, mode)
    },

    methods: {
        checkUserModuleAccess() {
            const budgetLoanModuleCode = '005.007'
            for (const avModule of store.state.user.userModules) {
                if (avModule.modules === budgetLoanModuleCode) {
                    return true
                }
            }
            return false
        },

        async loadAgreementByIdAndSetMode(agreementId, mode) {
            this.loading = true
            this.progress = 20

            this.NewItem = defaultValues

            this.initializeAsyncSelectOptions()
            await this.loadFrequencyOptions()

            // TODO: ! use mode rather than presence/abence of agreementId
            if (agreementId) {
                await this.loadWithAdditionals(agreementId)
            } else {
                this.currentMode = 'add'
                const defaults = await this.selectDefaults()
                this.NewItem.codeCurrency = defaults.codeCurrency
                this.NewItem.statusId = defaults.statusId
                this.NewItem.typeLoan = defaults.typeLoan
            }
            this.progress = 50
            await this.loadInitialAsyncOptions()

            switch (mode) {
                case 'view': {
                    this.currentMode = 'view'
                    break
                }
                case 'add-additional': {
                    const template = this.additionalContracts.find(item => item.flagActual === 1)
                    this.NewItem = JSON.parse(JSON.stringify(template))
                    this.handleOpenAdditionalContract()
                    break
                }
                case 'add-lower-level': {
                    const template = this.additionalContracts.find(item => item.flagActual === 1)
                    this.NewItem = JSON.parse(JSON.stringify(template))
                    this.handleOpenLowerLevelAgreement()
                    break
                }
                case 'edit': {
                    this.handleOpenEditMode()
                    break
                }
                default:
            }

            this.progress = 100
            this.loading = false
        },

        removeElement() {
            this.$forceUpdate();
        },

        selectionChange() {
            this.$forceUpdate()
        },

        initializeAsyncSelectOptions() {
            for (const col of this.keys) {
                if (col.type === 'asyncSelect') {
                    this.asyncSelectOptions[col.key] = []
                }
            }
        },

        async deleteAgreementById(id) {
            const response = await fetch(`/api/budget-loan/${id}`, { method: 'DELETE' })
            return response
        },

        // #region Formatters
        formatMaxLength(value, maximumLength) {
            value += ''
            value = value.length > maximumLength ? value.substring(0, maximumLength) : value
            return value
        },

        formatOnlyNumeric(value) {
            value += ''
            value = value.replace(/[^\d,. ]/g, "");
            return value
        },

        formatMaxFloat(value, maximumFloatLength) {
            value += ''
            value = value.replace(/[^\d,. ]/g, "");
            //const until = value.indexOf(',') !== -1 ? value.indexOf(',') + maximumFloatLength + 1 : value.length
            let until = value.length
            if (value.indexOf(',') !== -1) {
                until = value.indexOf(',') + maximumFloatLength + 1
            } else if (value.indexOf('.') !== -1) {
                until = value.indexOf('.') + maximumFloatLength + 1
            }
            value = value.substring(0, until)
            return value
        },

        formatDigitReadability(value) {
            if (!value) {
                return value
            }
            value += ''
            const removedSpaceString = value.replace(/\s/g, "")
            value = removedSpaceString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return value
        },

        formatWholeNumber(value) {
            value += ''
            value = value.replaceAll('.', '')
            value = value.replaceAll(',', '')
            return value
        },
        // #endregion

        setChosenItem(value) {
            if (value === null) {
                return
            } else if (value.id === this.NewItem.id) {
                return
            }
            this.NewItem = {...value}
            this.contractId = value.id

            if (this.NewItem.contractor.bin) {
                this.toggleContractorType('other')
            } else {
                this.toggleContractorType('gu')
            }
        },

        isDisabled(key) {
            if (['numberContractMain', 'flagActual'].includes(key)) {
                return true
            } else if (this.NewItem?.codeCurrency?.code === '398' && ['currencyRate'].includes(key)) {
                return true
            } else if (this.currentMode === 'add-additional') {
                if (notEditableAdditionalAgreementFields.includes(key)) {
                    return true
                }
            } else if (this.currentMode === 'add-lower-level') {
                if (notEditableLowerLevelAgreementFields.includes(key)) {
                    return true
                }
            } else if (this.currentMode === 'edit') {
                if (!editableFields.includes(key)) {
                    return true
                }
            } else if (this.currentMode === 'view') {
                return true
            }
            return false
        },

        // millisToDate(millis) {
        //     if (!millis) {
        //         return '';
        //     }
        //     const date = new Date(millis);
        //     const year = date.getFullYear();
        //     let month = date.getMonth() + 1;
        //     if (month.toString().length === 1) {
        //         month = '0' + month;
        //     }
        //     let day = date.getDate();
        //     if (day.toString().length === 1) {
        //         day = '0' + day;
        //     }
        //     return year + '-' + month + '-' + day;
        // },
        millisToDate(millis) {
            if (!millis) {
                return '';
            }
            const date = new Date(millis);

            // Check if time is 23:00:00 and add an hour if it is
            if (date.getHours() === 23 && date.getMinutes() === 0 && date.getSeconds() === 0) {
                date.setTime(date.getTime() + 3600000); // Adds 1 hour (3600000 milliseconds)
            }

            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month.toString();
            
            let day = date.getDate();
            day = day < 10 ? '0' + day : day.toString();

            return year + '-' + month + '-' + day;
        },

        // #region Handlers
        handleInput(key, value) {
            switch (key) {
                case 'amount':
                    value = parseFloat(value.replace(',', '.').replace(/\s/g, ''))
                    if (!value) {
                        value = null
                    }
                    break
                default:
                    break
            }
            this.NewItem = {...this.NewItem, [key]: value}
        },

        handleDateFormat(value) {
            let v = value
            if (value.indexOf('-') >= 5) {
                const year = value.slice(0, 4)
                const rest = value.slice(-5)
                v = year + '-' + rest
            }
            return v
        },

        async handleInputDate(key, value) {
            if (!value.startsWith('0')) {
                this.NewItem = { ...this.NewItem, [key]: value }

                if (key === 'dateContract' && this.NewItem.contractYears) {
                    let year = this.NewItem.dateContract.slice(0, 4)
                    year = parseInt(year) + parseInt(this.NewItem.contractYears) //TODO: account leap years
                    const newEndDate = year + this.NewItem.dateContract.slice(4)
                    this.NewItem = {...this.NewItem, contractDateEnd: newEndDate}
                }
                if (key === 'dateContract') {
                    // reset program
                    const codeProgCol = this.keys.find(col => col.key === 'codeProg')
                    // reset goal
                    const budgetLoanPurposeCol = this.keys.find(col => col.key === 'budgetLoanPurpose')
                    // reset contractor
                    this.NewItem.contractor = null
                    const contractorCol = this.keys.find(col => col.key === 'contractor')
                    
                    await this.loadSelects(codeProgCol, '')
                    await this.loadSelects(budgetLoanPurposeCol, '')
                    await this.loadSelects(contractorCol, '')
                }
            } // if allow year to start with 0, would reset input
        },

        async onContractorTypeChange(value) {
            await this.toggleContractorTypeAndLoadContractorOptions(value)
        },

        async handleSelectInput(key, value) {
            const oldValue = this.NewItem[key]

            this.NewItem = {...this.NewItem, [key]: value}

            // load dependent options
            switch (key) {
                case 'budgetLevel': {
                    if (!oldValue || (oldValue?.code !== value?.code)) {
                        // compute loanDirection
                        let budgetLevelId
                        if (value.id === 1) {
                            budgetLevelId = 1
                        } else if (value.id === 2 && !this.NewItem.numberContractMain) {
                            budgetLevelId = 2
                        }
                        const computedLoanDirection = this.asyncSelectOptions.loanDirection.find(item => item.budgetLevelId === budgetLevelId)
                        this.NewItem = {...this.NewItem, loanDirection: computedLoanDirection}

                        // reset creditor
                        this.NewItem.creditor = null
                        const creditorCol = this.keys.find(col => col.key === 'creditor')

                        // reset administrator
                        this.NewItem.codeGu = null
                        const administratorCol = this.keys.find(col => col.key === 'codeGu')

                         // reset contractor
                        this.NewItem.contractor = null
                        const contractorCol = this.keys.find(col => col.key === 'contractor')

                        // Reset prg and ppr
                        this.NewItem.prg = null
                        this.NewItem.ppr = null
                        this.NewItem.contractor = null
                        this.asyncSelectOptions = {...this.asyncSelectOptions, ppr: [], prg: [], contractor: []}

                        await this.loadSelects(creditorCol, '')
                        await this.loadSelects(administratorCol, '')
                        await this.loadSelects(contractorCol, '')
                    }
                    return
                }
                case 'codeGu': {
                    if (!oldValue || (oldValue?.code !== value?.code)) {
                        // Reset contractor
                        this.NewItem.contractor = null
                        this.asyncSelectOptions.contractor = []
                        const contractorColumn = this.keys.find(col => col.key === 'contractor')

                        // Reset prg
                        this.NewItem.prg = null
                        const prgColumn = this.keys.find(col => col.key === 'prg')

                        // Reset ppr
                        this.NewItem.ppr = null
                        this.asyncSelectOptions = {...this.asyncSelectOptions, ppr: []}

                        await this.loadSelects(contractorColumn, '')
                        await this.loadSelects(prgColumn, '')
                    }
                    return
                }
                case 'prg': {
                    if (!oldValue || (oldValue?.prg !== value?.prg)) {
                        // Reset ppr
                        this.NewItem.ppr = null
                        this.asyncSelectOptions.ppr = []
                        const pprColumn = this.keys.find(col => col.key === 'ppr')
                        await this.loadSelects(pprColumn, '')
                    }
                    return
                }
                default:
                    return
            }
        },

        handleContractYearInput(value) {
            if (this.NewItem.dateContract) {
                let year = this.NewItem.dateContract.slice(0, 4)
                year = parseInt(year) + parseInt(value) //TODO: account leap years
                const newEndDate = year + this.NewItem.dateContract.slice(4)
                const copy = {...this.NewItem}
                copy.contractDateEnd = newEndDate
                copy.contractYears = value
                this.NewItem = copy
            }
        },

        handleOpenAdditionalContract() {
            if (this.currentMode === 'add-additional') {
                this.contractorType = this.tempItem.contractorType
                delete this.tempItem.contractorType
                this.NewItem = this.tempItem
                this.currentMode = 'view'
                return
            }
            this.currentMode = 'add-additional'
            this.tempItem = JSON.parse(JSON.stringify(this.NewItem))
            this.tempItem.contractorType = this.contractorType
            this.NewItem.type = 1
            const mainAgreement = this.additionalContracts.find(item => item.type === 0)
            this.NewItem.numberContractMain = mainAgreement.numberContract
            this.NewItem.numberContract = mainAgreement.numberContract + '/' + this.additionalContracts.length
            this.NewItem.begDate = this.millisToDate(mainAgreement.begDate)
            this.NewItem.endDate = this.millisToDate(mainAgreement.endDate)
            this.NewItem.dateContract = ''
            this.NewItem.noteRu = ''
            this.NewItem.noteKz = ''
            this.NewItem.currencyRate = ''
            this.NewItem.file = '' // TODO: ! remove
            this.NewItem.files = []
            this.NewItem.flagActual = 1
            this.NewItem.followingInterestRepaymentDay = null
            this.NewItem.followingInterestRepaymentMonth = null
            this.NewItem.initialInterestRepaymentDate = null
        },

        handleOpenLowerLevelAgreement() {
            if (this.currentMode === 'add-lower-level') {
                this.contractorType = this.tempItem.contractorType
                delete this.tempItem.contractorType
                this.NewItem = this.tempItem
                this.currentMode = 'view'
                return
            }
            this.currentMode = 'add-lower-level'
            this.tempItem = JSON.parse(JSON.stringify(this.NewItem))
            this.tempItem.contractorType = this.contractorType
            const mainAgreement = this.additionalContracts.find(item => item.type === 0)
            this.NewItem.numberContractMain = mainAgreement.numberContract
            this.NewItem.begDate = this.millisToDate(mainAgreement.begDate)
            this.NewItem.endDate = this.millisToDate(mainAgreement.endDate)
            const lowerBudgetLevel = this.asyncSelectOptions['budgetLevel'].find(item => item.id === 2)
            const loadDirection = {...this.NewItem.loanDirection}
            this.handleSelectInput('budgetLevel', lowerBudgetLevel)
            this.NewItem.loanDirection = loadDirection
            this.NewItem.type = 0
            this.NewItem.numberContract = ''
            this.NewItem.dateContract = ''
            this.NewItem.noteRu = ''
            this.NewItem.noteKz = ''
            this.NewItem.currencyRate = ''
            this.NewItem.file = '' // TODO: ! remove
            this.NewItem.files = []
            this.NewItem.flagActual = 1
            this.NewItem.followingInterestRepaymentDay = null
            this.NewItem.followingInterestRepaymentMonth = null
            this.NewItem.initialInterestRepaymentDate = null
        },

        handleOpenEditMode() {
            this.idsOfFilesToDelete = []
            if (this.currentMode === 'edit') {
                this.NewItem = this.tempItem
                this.currentMode = 'view'
                return
            }
            this.currentMode = 'edit'
            this.tempItem = JSON.parse(JSON.stringify(this.NewItem))
        },

        handleLoadSelects: debounce(async function (col, query) {
            if (!col.fetchAll) {
                await this.loadSelects(col, query)
            }
        }, 300),

        async deleteAgreement() {
            this.loading = true
            const currentAgreementId = this.NewItem.id

            const response = await this.deleteAgreementById(currentAgreementId)

            if (response.ok) {
                this.makeToast('Договор удален', 'Сообщение', 'success')
                if (currentAgreementId !== this.mainAgreement.id) {
                    this.contractId = null
                    await this.loadAgreementByIdAndSetMode(this.mainAgreement.id, 'view')
                } else {
                    setTimeout(() => {
                        this.$router.replace('/budget-loan-table')
                    }, 500)
                }
            } else {
                const errorResponse = await response.json()
                const message = errorResponse?.args[0]?.value[0]
                this.makeToast(message, 'Ошибка', 'danger')
            }
            this.loading = false
        },
        // #endregion

        async handleDeleteAgreement() {
            this.$bvModal.msgBoxConfirm(
                'Удалить выбранный договор?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(confirmed => {
                    if (confirmed) {
                        this.deleteAgreement()
                    }
                })
                .catch(error => {
                    this.makeToast('Не удалось удалить договор', 'Ошибка удаления', 'danger')
                    console.error(error.toString())
                })
        },

        async getFullAgreements(id) {
            const response = await fetch(`/api/budget-loan/full/with-additionals/${id}`)
            if (!response.ok) {
                this.makeToast('Не удалось загрузить договора', 'Сообщение', 'danger')
                return
            }
            const agreements = await response.json()

            const fullAgreements = this.transformReceivedAgreements(agreements)
            return fullAgreements
        },

        transformReceivedAgreements(agreemnts) {
            const transformed = agreemnts.map(item => this.transformSingleReceivedAgreement(item))
            const main = transformed.find(item => item.type === 0)
            // Проставление номера основого договара на допики для отображения
            transformed.map(item => {
                if (item.numberContractMain) {
                    item.numberContractMainId = item.numberContractMain
                    item.numberContractMain = main.numberContract
                }
            })
            return transformed
        },

        transformSingleReceivedAgreement(source) {
            const target = JSON.parse(JSON.stringify(source))

            if (target.contractor.gu) {
                target.contractor = target.contractor.gu
            } else {
                target.contractor = target.contractor.contractor
            }

            if (target.budgetLevel.id === 1) {
                target.creditor = target.creditor.abp
            } else {
                target.creditor = target.creditor.gu
            }

            target.contractDateEnd = this.millisToDate(target.contractDateEnd)
            target.dateContract = this.millisToDate(target.dateContract)

            // Проставить тип для генерация соответсвуещего label-а для 'contractor'
            if (target.contractor.bin) {
                this.toggleContractorType('other')
            } else {
                this.toggleContractorType('gu')
            }

            for (const column of this.keys) {
                if (column.type === 'asyncSelect' && Boolean(target[column.key])) {
                    this.createLabel(target[column.key], column.key, (target.budgetLevel.id === 1))
                }
            }

            target.files.forEach(fileItem => {
                fileItem.alreadyInStorage = true
            })

            return target
        },

        async loadSelects(col, query) {
            this.asyncSelectOptions[col.key] = await this.fetchOptions(col, query)
            this.asyncSelectOptions = JSON.parse(JSON.stringify(this.asyncSelectOptions))
        },

        async fetchOptions(col, query) {
            let url = col.selectsUrl
            let queryString = query

            if (noSearchSelects.includes(col.key)) {
                queryString = ''
            }

            if (['budgetLoanPurpose', 'creditor', 'codeGu', 'contractor', 'prg', 'ppr', 'codeProg'].includes(col.key)) {
                url = this.getUrl(col.key)                
            }

            if (!url) {
                return []
            }

            const response = await fetch(url + queryString)
            if (!response.ok) {
                this.makeToast(`Не удалось подтянуть варианты для ${col.key}`, 'Ошибка', 'danger')
                return
            }

            const data = await response.json()
            let items = data.items ? data.items : data

            items.map(item => this.createLabel(item, col.key, this.isRepublicanBudget))

            this.sortOptions(items, col.key)

            // exclude 'Грант' from 'typeLoan' options
            if (col.key === 'typeLoan') {
                items = items.filter(item => item.code !== '3')
            } else if (['frequency', 'frequencyRate', 'frequencyIncome'].includes(col.key)) {
                items = items.filter(item => ['001', '002', '003', '004'].includes(item.code))
            }

            return items
        },

        // TODO: ! pass budgetLevel; contractorType; adminRegion and adminBudgetType;
        getUrl(key) {
            const budgetLevel = this.NewItem?.budgetLevel?.id
            let agreementDate = this.NewItem?.dateContract
            if (typeof agreementDate === 'number') {
                agreementDate = this.millisToDate(agreementDate)
            }
            let url

            switch (key) {
                case 'creditor': {
                    if (budgetLevel === 1) {
                        url = 'api/dict/ebk_func_types/pages/by-type-and-budget-level?type=3&budget-level=1&search-text='
                    } else {
                        url = 'api/dict/gu/server-region/pages/by-code-or-name-and-budget-type?budget-type=02&search-text='
                    }
                    break
                }
                case 'codeGu': {
                    if (budgetLevel === 1) {
                        url = 'api/dict/gu/pages/by-code-or-name-and-budget-type-less-than-three?search-text='
                    } else {
                        url = 'api/dict/gu/main/server-region/pages/by-code-or-name-and-budget-type?budget-type=02&search-text='
                    }
                    break
                }
                case 'contractor': {
                    if (this.contractorType === 'ГУ') {
                        if (agreementDate && (budgetLevel??false)) {
                            const budgetLevelCode = '0'+budgetLevel
                            url = `api/dict/gu/contractor/pages?budget-level=${budgetLevelCode}&date=${agreementDate}&&search-text=`
                        }
                    } else {
                        url = 'api/dict/bc_contractor/server-region/by-bin-or-name?search-text='
                    }
                    break
                }
                case 'prg': {
                    const abp = this.NewItem.codeGu?.code?.substring(0, 3)
                    url = `api/dict/ebk_func_types/pages/by-abp-and-type-and-prg?abp=${abp}&type=4&search-text=`
                    break
                }
                case 'ppr': {
                    const abp = this.NewItem?.codeGu?.code?.substring(0, 3)
                    const prg = this.NewItem?.prg?.prg
                    url = `api/dict/ebk_func_types/pages/by-abp-and-type-and-prg?abp=${abp}&prg=${prg}&type=5&search-text=`
                    break
                }
                case 'codeProg': {
                    if (agreementDate) {
                        url = `/api/dict/program/pages/by-name-and-server-region?date=${agreementDate}&name=`
                    }
                    break
                }
                case 'budgetLoanPurpose': {
                    if (agreementDate) {
                        url = `/api/dict/bc_goal/active-by-date?date=${agreementDate}`
                    }
                    break
                }
                default:
                    break
            }

            return url
        },

        async loadFrequencyOptions() {
            const response = await fetch('/api/stat-dict/report-frequency/parentless')
            if (!response.ok) {
                this.makeToast('Не удалось загрузить варианты перодичностей', 'Сообщение', 'danger')
            }

            const data = await response.json()
            let items = data.filter(item => ['001', '002', '003', '004'].includes(item.code))
            items = items.map(item => this.createLabel(item)) 
            this.frequencyOptions = items
        },

        sortOptions(items, columnName) {
            switch (columnName) {
                case 'budgetLoanPurpose': {
                    items.map(item => item.code = parseInt(item.code))
                    items.sort((a, b) => a.code - b.code)
                    return
                }
                case 'budgetLevel': {
                    items.sort((a, b) => a.id - b.id)
                    return
                }
                case 'prg': {
                    items.sort((a, b) => a.prg - b.prg)
                    return
                }
                case 'ppr': {
                    items.sort((a, b) => a.ppr - b.ppr)
                    return
                }
                default:
                    return;
            }
        },

        createLabel(item, columnName = null, isRepublicanBudget = false) {
            const nameRu = item.nameRu ? item.nameRu : item.name_ru

            switch (columnName) {
                case 'codeCurrency': {
                    item.label = nameRu
                    break
                }
                case 'prg': {
                    let prg = item.prg
                    const zerosToAdd = 3 - (prg + '').length
                    for (let i = 0; i < zerosToAdd; i++) {
                        prg = '0' + prg
                    }
                    item.label = `${prg} - ${nameRu}`
                    break
                }
                case 'ppr': {
                    let ppr = item.ppr
                    const zerosToAdd = 3 - (ppr + '').length
                    for (let i = 0; i < zerosToAdd; i++) {
                        ppr = '0' + ppr
                    }
                    item.label = `${ppr} - ${nameRu}`
                    break
                }
                case 'codeProg': {
                    item.label = nameRu
                    break
                }
                case 'budgetLevel':
                case 'statusId': {
                    item.label = `${item.id} - ${nameRu}`
                    break
                }
                case 'creditor': {
                    if (isRepublicanBudget) {
                        item.label = `${item.abp} - ${nameRu}`
                    } else {
                        const code = item.code ? item.code : item.fullCode
                        item.label = `${code} - ${nameRu}`
                    }
                    break
                }
                case 'contractor': {
                    if (this.contractorType === 'ГУ') {
                        const code = item.code ? item.code : item.fullCode
                        item.label = `${code} - ${nameRu}`
                    } else {
                        item.label = `${item.bin} - ${item.nameRu}`
                    }
                    break
                }
                default: {
                    if (typeof item === 'object') {
                        const code = item.code ? item.code : item.fullCode
                        item.label = `${code} - ${nameRu}`
                    }
                    break
                }
            }

            return item
        },

        async loadInitialAsyncOptions() {
            for (const col of this.keys) {
                if (col.type !== 'asyncSelect' || ['frequency', 'frequencyRate', 'frequencyIncome'].includes(col.key)) {
                    continue
                }
                await this.loadSelects(col, "")
            }
        },

        async selectDefaults() {
            const defaults = {}
            for (const col of this.keys) {
                switch (col.key) {
                    case 'codeCurrency': {
                        const response = await fetch(col.selectsUrl)
                        if (response.ok) {
                            const result = await response.json()
                            const item = result.find(it => it.code === '398')
                            item.label = item.nameRu
                            defaults.codeCurrency = item
                        }
                        break
                    }
                    case 'statusId': {
                        const response = await fetch('api/dict/bc_status/pages?search-text=Действ')
                        if (response.ok) {
                            const result = await response.json()
                            const item = result.items[0]
                            item.label = item.id + ' - ' + item.nameRu
                            defaults.statusId = item
                        }
                        break
                    }
                    case 'typeLoan': {
                        const response = await fetch('api/dict/bc_type_loan/pages?search-text=Кредит')
                        if (response.ok) {
                            const result = await response.json()
                            const item = result.items[0]
                            item.label = item.id + ' - ' + item.nameRu
                            defaults.typeLoan = item
                        }
                        break
                    }
                    default:
                        break
                }
                // if (col.key === 'codeCurrency') {
                //     const response = await fetch(col.selectsUrl + 'тенге')
                //     if (response.ok) {
                //         const result = await response.json()
                //         const item = result.items[0]
                //         item.label = item.nameRu
                //         defaults.codeCurrency = item
                //     }
                // } else if (col.key === 'statusId') {
                //     const response = await fetch('api/dict/bc_status/pages?search-text=Действ')
                //     if (response.ok) {
                //         const result = await response.json()
                //         const item = result.items[0]
                //         item.label = item.id + ' - ' + item.nameRu
                //         //this.NewItem
                //         defaults.statusId = item
                //     }
                // }
            }
            return defaults
        },

        checkForm() {
            this.$v.NewItem.$touch();

            if (!this.$v.NewItem.$error) {
                this.saveOrUpdate()
            }
        },

        openAdditionalsModal(action = null) {
            if (action === 'open') {
                this.showModal = true
            } else if (action === 'close') {
                this.showModal = false
            } else {
                this.showModal = !showModal
            }
        },

        convertObjectTypeFields(object) {
            const cols = this.keys
            for (const col of cols) {
                if (col.type === 'asyncSelect' && object[col.key] && col.key === 'creditor') {
                    let trackBy = 'fullCode'
                    if (object.creditor.code) {
                        trackBy = 'code'
                    }
                    if (this.isRepublicanBudget) {
                        trackBy = 'abp'
                    }
                    object[col.key] = object[col.key][trackBy]
                }
                else if (col.key === 'contractor') {
                    if (this.contractorType === 'ГУ') {
                        object.contractor = object.contractor.code
                    } else {
                        object.contractor = object.contractor.bin
                    }
                }
                else if (col.key === 'codeGu') {
                    object.codeGu = object.codeGu.code
                }
                else if (col.type === 'asyncSelect' && object[col.key]) {
                    object[col.key] = object[col.key][col.trackBy]
                }
                // else if (this.isRepublicanBudget && (col.key === 'numberContractMain') && object.type === 0) {
                //     object[col.key] = null
                // } TODO: ? if needed
                else if (this.NewItem?.codeCurrency?.code === '398' && col.key === 'currencyRate') {
                    object[col.key] = null
                }
                else if (col.validation?.numeric && object[col.key] && typeof object[col.key] === 'string') {
                    object[col.key] = parseFloat(object[col.key].replace(/\s/g, "").replace(/\,/g, "."))
                }
            }

            if ((object.frequency ?? false) && (typeof object.frequency === 'object')) {
                object.frequency = object.frequency.code
            }
            if ((object.frequencyIncome ?? false) && (typeof object.frequencyIncome === 'object')) {
                object.frequencyIncome = object.frequencyIncome.code
            }
            if ((object.frequencyRate ?? false) && (typeof object.frequencyRate === 'object')) {
                object.frequencyRate = object.frequencyRate.code
            }

            if (['add-additional', 'add-lower-level'].includes(this.currentMode)) {
                if (this.tempItem.type === 0) {
                    object.numberContractMain = this.tempItem.id
                } else {
                    const main = this.additionalContracts.find(item => item.type === 0)
                    object.numberContractMain = main.id
                }
                delete object.id
            }
            if (this.currentMode === 'edit') {
                if (object.numberContractMain && object.numberContractMainId) {
                    object.numberContractMain = object.numberContractMainId
                }
            }
            return object
        },

        async handleAddFile(file) {
            let randomId = Math.floor(Math.random() * 101)
            while (this.NewItem.files.findIndex(item => item.id === randomId) !== -1) {
                randomId = Math.floor(Math.random() * 101)
            }

            this.NewItem.files.push({
                id: randomId,
                fileName: file.name,
                description: null,
                agreementId: this.NewItem.id,
                multipartFile: file
            })
        },

        async handleRemoveFile(id) {
            const toDelete = this.NewItem.files.find(fileItem => fileItem.id === id)
            if (toDelete.alreadyInStorage) {
                this.idsOfFilesToDelete.push(id)
            }
            this.NewItem.files = this.NewItem.files.filter(item => item.id !== id) // TODO: ! remove: this.$emit('removeFile', id)
        },

        async saveOrUpdate() {
            this.loading = true
            try {
                let method = 'POST'
                let message = 'Элемент сохранен'

                this.NewItem.userName = this.userSub
                const convertedNewItem = this.convertObjectTypeFields(JSON.parse(JSON.stringify(this.NewItem)))

                if (this.currentMode === 'edit') {
                    method = 'PUT'
                    message = 'Элемент редактирован'
                }

                const response = await fetch('/api/budget-loan', {
                    method: method,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(convertedNewItem)
                })

                if (response.ok) {
                    this.makeToast(message, 'Сообщение', 'success')

                    let idToLoad
                    let idToAttachFiles
                    switch (this.currentMode) {
                        case 'add':
                        case 'add-additional': {
                            const createdAgreement = await response.json()
                            idToLoad = createdAgreement.id
                            idToAttachFiles = createdAgreement.id
                            break
                        }
                        case 'add-lower-level': {
                            const createdAgreement = await response.json()
                            idToAttachFiles = createdAgreement.id
                            idToLoad = this.mainAgreement.id
                            break
                        }
                        case 'edit': {
                            idToLoad = this.NewItem.id
                            idToAttachFiles = this.NewItem.id
                            const alerts = await response.json()
                            alerts.forEach(alertMessage => {
                                this.makeToast(alertMessage, 'Примечание', 'danger')
                            })
                            break
                        }
                        default:
                    }

                    await this.applyFileEdits(idToAttachFiles)

                    await this.loadAgreementByIdAndSetMode(idToLoad, 'view')
                } else {
                    let text = 'Извините, произошла ошибка на сервере или некорректно заполнены поля.'
                    const errorResponse = await response.json()
                    const key = errorResponse.key
                    const message = errorResponse?.args[0]?.value[0]
                    if (key) {
                        text = key
                    }
                    if (message) {
                        text = message
                    }
                    this.makeToast(text, 'Ошибка сохранения', 'danger');
                    throw new Error('Network response was not ok');
                }
            } catch (error) {
                console.error('There was a problem with the fetch operation:', error);
            } finally {
                this.loading = false
            }
        },

        async applyFileEdits(agreementId) {
            // Deletion
            if (this.idsOfFilesToDelete.length > 0) {
                const idsString = this.idsOfFilesToDelete
                    .reduce((previous, current) => {return previous+`${current},`}, '')
                    .slice(0, -1)
                const response = await fetch(`api/budget-loan/delete-storage-files?fileIds=${idsString}`, { method: 'DELETE' })
                if (!response.ok) {
                    const error = await response.json()
                    console.error(error)
                    this.makeToast('Не удалось удалить файл(ы)', 'Ошибка', 'danger')
                    return
                }
            }

            // Addition
            const filesToSave = this.NewItem.files.filter(fileItem => !fileItem.alreadyInStorage)
            const formDataArray = filesToSave.map(fileItem => {
                fileItem.description = (fileItem.description ?? '')
                
                const formData = new FormData()
                formData.append('fileName', fileItem.fileName)
                formData.append('agreementId', agreementId)
                formData.append('description', fileItem.description)
                formData.append('multipartFile', fileItem.multipartFile)
                return formData
            })
            // Create an array of Axios promises
            const requests = formDataArray.map(formData => axios.post('api/budget-loan/storage-upload', formData))

            // Use axios.all to send multiple requests concurrently
            axios.all(requests)
            .then(axios.spread((...responses) => {
                // responses contains the response for each request
                const data = responses.map(response => response.data)
            }))
            .catch(error => {
                this.makeToast('Не удалось сохранить загруженные(-ый) файл(ы)', 'Ошибка', 'danger')
                console.error(error)
            })

            // description edit
            for (const fileItem of this.NewItem.files) {
                if (fileItem.alreadyInStorage) {
                    const prevDesc = this.tempItem.files.find(tempFile => tempFile.id === fileItem.id)?.description
                    if (fileItem.description !== prevDesc) {
                        await fetch('api/budget-loan/update-storage-file', {
                            method: 'PUT',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                id: fileItem.id,
                                fileName: fileItem.fileName,
                                description: fileItem.description,
                                agreementId: fileItem.agreementId
                            })
                        })
                    }
                }
            }
        },

        makeToast(message, title, variant) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true
            });
        },

        changeModeFromAddToReadOnly() {
            if (this.currentMode === 'add') {
                this.currentMode = 'view'                
            } // TODO: uncomment after changing mode implementation
        },

        reloadFact() {
            // reload fact
            if (this.actualAgreement.index >= 0) {
                this.actualAgreement.index = -1                
            } else {
                this.actualAgreement.index -= 1
            }

            // reload forecast
            this.forecastTabKey = this.forecastTabKey + 1
        },

        reloadForecast() {
            this.forecastTabKey = this.forecastTabKey + 1
        },

        async loadWithAdditionals(agreementId) {
            const fullFieldsAgreements = await this.getFullAgreements(agreementId)

            this.progress = 50

            fullFieldsAgreements.map(item => {
                item.label = item.numberContract
                if (item.type === 0) {
                    item.label += ' (Основной) от ' + item.dateContract
                } else {
                    item.label += ' (Дополнение) от ' + item.dateContract
                }
                if (item.flagActual === 1) {
                    item.label += ' (Актуальный)'
                }
            })
            fullFieldsAgreements.forEach((item, index) => {
                item.index = index
            })

            this.additionalContracts = fullFieldsAgreements

            const displayAgreement = fullFieldsAgreements.find(item => item.id === agreementId)
            
            if (displayAgreement.contractor.bin) {
                this.toggleContractorType('other')
            } else {
                this.toggleContractorType('gu')
            }

            this.NewItem = JSON.parse(JSON.stringify(displayAgreement))

            const mainTemplate = fullFieldsAgreements.find(item => item.type === 0)
            this.mainAgreement = JSON.parse(JSON.stringify(mainTemplate))
            
            const actualTemplate = fullFieldsAgreements.find(item => item.flagActual === 1)
            this.actualAgreement = JSON.parse(JSON.stringify(actualTemplate))

            this.contractId = agreementId
        },

        toggleContractorType(mode = null) {
            if (mode === null) {
                if (this.contractorType === 'ГУ') {
                    this.contractorType = 'Иные (по БИН-у)'
                } else {
                    this.contractorType = 'ГУ'
                }
            } else if (['ГУ', 'gu'].includes(mode)) {
                this.contractorType === 'ГУ'
            } else if (['Иные (по БИН-у)', 'other'].includes(mode)) {
                this.contractorType = 'Иные (по БИН-у)'
            }
        },

        async toggleContractorTypeAndLoadContractorOptions(mode = null) {
            this.toggleContractorType(mode)

            this.NewItem = {...this.NewItem, contractor: null} // TODO: ? .contractor = null
            const contractorColumn = this.keys.find(col => col.key === 'contractor')
            await this.loadSelects(contractorColumn, '')
        },

        goBack() {
            this.$router.back()
        }
    },

    computed: {
        isRepublicanBudget() {
            return this.NewItem?.budgetLevel?.id === 1
        },

        isActive() {
            return this.NewItem?.statusId?.id === 1
        },

        isActual() {
            return this.NewItem?.flagActual === 1
        },

        isAdditionalAddable() {
            return this.isActive && this.isActual
        },

        isLowerLevelAddable() {
            return this.isActive && this.isActual && this.isRepublicanBudget
        }
    },

    watch: {
        'NewItem.codeCurrency': {
            handler: function (newValue, oldValue) {
                if (newValue?.code === '398') {
                    this.NewItem.currencyRate = null // задействовать watcher
                }
            },
            deep: true
        }
    }
}
</script>

<style lang="scss" scoped>
.my-table-input {
    width: 60px;
}

.additional-contract-edit-section {
    width: calc(100% - 112px);
    // font-size: rem(20);
    // font-weight: 800;
    height: 80px;
    display: inline-flex;
    align-items: center;
    padding: 0 20px;
    color: #fff;
    background-color: #E39259;
    position: fixed;
    top: 56px;
    left: 56px;
    z-index: 1009;
    transition: 0.2s all ease;
}

.scrolled {
    .additional-contract-edit-section {
        top: -80px;
    }
}

.full-board {
    .additional-contract-edit-section {
        left: 300px;
        width: calc(100% - 356px);
    }
}

.logo-region {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.legend .form-group {
    text-align: center;
}

.read-only-multiselect .multiselect__input {
    pointer-events: none;
    background-color: #f5f5f5;
    /* Use a background color to indicate it's read-only */
}
</style>
