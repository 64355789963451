export const millisToDate = (millis: number): string => {
    if (!millis) {
        return '';
    }
    const date = new Date(millis);
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1;
    if (month.toString().length === 1) {
        month = '0' + month;
    }
    let day: number | string = date.getDate();
    if (day.toString().length === 1) {
        day = '0' + day;
    }
    return year + '-' + month + '-' + day;
}

export const millisToYear = (millis: number): number => {
    const date = new Date(millis)
    return date.getFullYear()
}

export const deepCopy = (data: any): any => {
    return JSON.parse(JSON.stringify(data))
}

// #region number formatting
export const formatDigitReadability = (number: number | string): string => {
    number += ""
    const removedSpaceString = (number as string).replace(/\s/g, "")
    number = removedSpaceString.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    return number
}

export const formatMaxFloat = (number: number | string): string => {
    let value = number + ''
    let minusSign = ''
    if (value?.charAt(0) === '-') {
        minusSign = '-'
    }

    const maximumFloatLength = 2
    value = value.replace(/[^\d,. ]/g, "")
    let until = value.length
    if (value.indexOf(',') !== -1) {
        until = value.indexOf(',') + maximumFloatLength + 1
    } else if (value.indexOf('.') !== -1) {
        until = value.indexOf('.') + maximumFloatLength + 1
    }
    value = value.substring(0, until)

    if (value.endsWith('.00')) {
        value = value.substring(0, value.indexOf('.'))
    }

    return minusSign + value
}

export const rounding = (number: number | string): string => {
    let value = number + ''
    let minusSign = ''
    if (value && value?.charAt(0) === '-') {
        minusSign = '-'
    }
    value = value.replace(/[^\d,. ]/g, "")
    let thirdIndex
    if (value.indexOf(',') !== -1) {
        thirdIndex = value.indexOf(',') + 3
    } else if (value.indexOf('.') !== -1) {
        thirdIndex = value.indexOf('.') + 3
    }
    if (thirdIndex) {
        const thirdNumber = parseInt(value[thirdIndex])
        if (thirdNumber >= 5 && thirdNumber <= 9) {
            value = (parseFloat(value) + 0.01).toString()
        }
    }

    return minusSign + value
}

export const formatNumberCombined = (number: number | string): string => {
    return formatDigitReadability(formatMaxFloat(rounding(number)))
}
// #endregion

export const calculateTotal = (scheduleData: any[], column: string): number => {
    let total = 0
    scheduleData.forEach((item) => {
        if (item[column]) {
            total += (item[column]??0)
        }
    })
    return total
}
