<template>
    <div>
        <!-- Панель управления -->
        <div class="filter-container">
            <!-- Хлебная крошка -->
            <div v-if="this.currentMode !== 'add'" class="filter-breadcrumb">
                <span class="item-breadcrumb">
                {{ mainAgreement.numberContract }}
                </span>
                <span class="item-breadcrumb" @click="openAdditionalsModal('open')">
                    <template v-if="NewItem.type === 0">
                        Основной
                    </template>
                    <template v-else>
                        {{ NewItem.numberContract }}
                    </template>
                </span>    
            </div>
            <div style="display: flex;">
                <b-button v-if="!editMode" @click="goBack">
                    К реестру
                </b-button>
                <div v-if="!editMode" style="display: flex; margin-left: 15px;">
                    <b-button v-if="agreement.type === 1" @click="handleImportPreviousSchedule" variant="success">
                        Импортировать предыдущий график
                    </b-button>
                    <b-button @click="handleGenerateSchedule" variant="success">
                        Сгенерировать новый график
                    </b-button>
                    <b-button @click="handleEditMode(true)" variant="primary">
                        <div style="display: flex; align-items: center">
                            <i class="icon icon-pencil-edit"></i>
                            <span class="left"> Редактировать </span>
                        </div>
                    </b-button>
                </div>
                <div v-else>
                    <div style="display: flex; justify-content: space-between">
                        <div>
                            <b-button
                                @click="handleEditMode(false)"
                                variant="danger"
                            >
                                Отмена
                            </b-button>
                        </div>
                        <div style="margin: 0 10px 0 10px; display: flex; align-items: center">
                            <b-form-checkbox switch v-model="editInterest" @change="onEditInterestCheck"/>
                            <span>Редактировать кол-во дней</span>
                        </div>
                        <div style="margin-left: 10px;">
                            <b-button @click="handleSaveEdited" variant="success">
                                Сохранить
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="inner-container">
            <div>
                <div style="display: flex; width: 100%;">
                    <div class="parameter-item">
                        <span class="parameter-title">Периодичность поступления</span>
                            <multiselect
                                v-model="frequencyIncome"
                                :options="frequencyOptions"
                                label="label"
                                style="margin-top: 5px;"
                                :disabled="editInterest"
                            />
                    </div>
                    <div class="parameter-item">
                        <span class="parameter-title">Периодичность погашения основного долга</span>
                            <multiselect
                                v-model="frequency"
                                :options="frequencyOptions"
                                label="label"
                                style="margin-top: 5px;"
                                :disabled="editInterest"
                            />
                    </div>
                    <div class="parameter-item">
                        <span class="parameter-title">Периодичность погашения вознаграждения</span>
                            <multiselect
                                v-model="frequencyRate"
                                :options="frequencyOptions"
                                label="label"
                                style="margin-top: 5px;"
                                :disabled="editInterest"
                            />
                    </div>
                    <div class="parameter-item">
                        <span class="parameter-title">Дата первого поступления</span>
                            <b-form-input
                                type="date"
                                :formatter="handleDateFormat"
                                :value="millisToDate(firstInflowDate)"
                                @input="value => handleInputDate('firstInflowDate', value)"
                                style="margin-top: 5px;"
                                :disabled="editInterest"
                            />
                    </div>
                    <div class="parameter-item">
                        <span class="parameter-title">Начальная дата погашения основного долга</span>
                            <b-form-input
                                type="date"
                                :formatter="handleDateFormat"
                                :value="millisToDate(firstPrincipalDate)"
                                @input="value => handleInputDate('firstPrincipalDate', value)"
                                style="margin-top: 5px;"
                                :disabled="editInterest"
                            />
                    </div>
                    <div class="parameter-item">
                        <span class="parameter-title">Начальная дата погашения вознаграждения</span>
                            <b-form-input
                                type="date"
                                :formatter="handleDateFormat"
                                :value="millisToDate(firstInterestDate)"
                                @input="value => handleInputDate('firstInterestDate', value)"
                                style="margin-top: 5px;"
                                :disabled="editInterest"
                            />
                    </div>
                    <div class="parameter-item">
                        <span class="parameter-title">Последующая дата погашения вознаграждения</span>
                        <div style="display: flex; column-gap: 5px;">
                            <b-form-input
                                type="number"
                                v-model="otherDatesDay"
                                min="1" max="28"
                                placeholder="День"
                                style="margin-top: 5px;"
                                :disabled="editInterest"
                            />
                            <b-form-input
                                type="number"
                                v-model="otherDatesMonth"
                                placeholder="Месяц"
                                min="1" max="12"
                                style="margin-top: 5px;"
                                :disabled="editInterest"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <b-progress variant="success" v-show="progress < 100" height="10px" :value="progress" striped animated></b-progress>

            <!-- Таблица с выбранным договором -->
            <div class="table-container b-table-sticky-header">
                <b-table
                    :fields="tableFields"
                    :items="scheduleData"
                    responsive="true"
                    bordered
                    head-variant="light"
                    no-border-collapse
                    sticky-header="true"
                >

                <template #thead-top="data">
                        <b-tr>
                            <b-th colspan="6"></b-th>
                            <b-th colspan="5" class="text-center">по выплатам вознаграждений</b-th>
                            <b-th v-if="editMode"></b-th>
                        </b-tr>
                    </template>

                    <template #head(datePayment)="scope">
                        <div>{{ tableFields[3].label }}</div>
                    </template>

                    <template #cell(rowNumber)="data">
                        {{ data.index + 1 }}
                    </template>

                    <template #cell(year)="data">
                        {{ millisToYear(data.item.datePayment) }}
                    </template>

                    <template #cell(datePayment)="data">
                        <template v-if="!editMode || editInterest">
                            <div class="text-right no-wrap-space">
                                {{ millisToDate(data.value) }}
                            </div>
                        </template>
                        <template v-else>
                            <b-form-input
                                type="date"
                                :formatter="handleDateFormat"
                                :value="millisToDate(data.value)"
                                @input="
                                    (value) =>
                                        handleFieldChange(
                                            'datePayment',
                                            value,
                                            data.index
                                        )
                                "
                            />
                        </template>
                    </template>

                    <template #cell(dateBeg)="data">
                        <div class="text-right no-wrap-space">
                            {{ millisToDate(data.value) }}
                        </div>
                    </template>

                    <template #cell(dateEnd)="data">
                        <div class="text-right no-wrap-space">
                            {{ millisToDate(data.value) }}
                        </div>
                    </template>

                    <template #cell(dayCount)="data">
                        <template v-if="!editMode || !editInterest || (!data.value && data.value !== 0)">
                            <div class="text-right no-wrap-space">
                                {{ data.value }}
                            </div>
                        </template>
                        <template v-else>
                            <div style="width: 70px;">
                            <b-form-input
                                :value="data.value"
                                @input="
                                    (value) =>
                                        handleFieldChange(
                                            'dayCount',
                                            value,
                                            data.index
                                        )
                                "
                                type="number"
                            />
                        </div>
                        </template>
                    </template>

                    <template #cell(leftPrincipal)="data">
                        <div class="text-right no-wrap-space">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>

                    <template #cell(sumPayment)="data">
                        <template v-if="!editMode || editInterest">
                            <div class="text-right no-wrap-space">
                                {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                            </div>
                        </template>
                        <template v-else>
                            <div style="min-width: 200px;">
                            <b-form-input
                                :value="data.value"
                                @input="
                                    (value) =>
                                        handleFieldChange(
                                            'sumPayment',
                                            value,
                                            data.index
                                        )
                                "
                                type="number"
                            />
                        </div>
                        </template>
                    </template>

                    <template #cell(sumBase)="data">
                        <div class="text-right no-wrap-space">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>

                    <template #cell(sumPaymentPrincipal)="data">
                        <template v-if="!editMode || editInterest">
                            <div class="text-right no-wrap-space">
                                {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                            </div>
                        </template>
                        <template v-else>
                            <div style="min-width: 200px;">
                            <b-form-input
                                :value="data.value"
                                @input="
                                    (value) =>
                                        handleFieldChange(
                                            'sumPaymentPrincipal',
                                            value,
                                            data.index
                                        )
                                "
                                type="number"
                            />
                        </div>
                        </template>
                    </template>

                    <template #cell(sumPaymentInterest)="data">
                        <div class="text-right no-wrap-space">
                            {{ formatDigitReadability(formatMaxFloat(rounding(data.value))) }}
                        </div>
                    </template>

                    <template #cell()="data">
                        <div class="text-right">{{ data.value }}</div>
                    </template>

                    <template v-if="editMode && !editInterest" #cell(action)="data">
                        <b-dropdown dropleft class="more-dropdown">
                            <template v-slot:button-content>
                                <i class="icon icon-more"></i>
                            </template>
                            <template>
                                <b-dropdown-item-btn @click="handleAddRow(data.item.id, true)">
                                    Добавить строку выше
                                </b-dropdown-item-btn>
                                <b-dropdown-item-btn @click="handleAddRow(data.item.id, false)">
                                    Добавить строку ниже
                                </b-dropdown-item-btn>
                                <b-dropdown-item-btn @click="handleRemoveRow(data.item.id)">
                                    <span style="color: red">Удалить</span>
                                </b-dropdown-item-btn>
                            </template>
                        </b-dropdown>
                    </template>

                    <template v-if="scheduleData.length > 0" #bottom-row="data">
                        <td class="sticky-bottom-row" :class="warning"></td>
                        <td class="text-left bold sticky-bottom-row" colspan="3" :class="warning">
                            <div style="display: flex; align-items: center;">
                                <div>ИТОГО</div>
                                <div v-if="!doesAmountAlign" class="error on-right">
                                    <i class="icon icon-danger"></i>
                                    <div class="pop-up">
                                        <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                        <p>Сумма договора не соответствует сумме поступлений и/или сумме погашений.</p>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="text-right bold sticky-bottom-row" :class="warning">{{ formatDigitReadability(formatMaxFloat(rounding(calculateTotalSum(scheduleData)))) }}</td>
                        <td class="text-right bold sticky-bottom-row" :class="warning">{{ formatDigitReadability(formatMaxFloat(rounding(calculateTotalPrincipal(scheduleData)))) }}</td>
                        <td class="sticky-bottom-row" colspan="4" :class="warning"></td>
                        <td class="text-right bold sticky-bottom-row" :class="warning">{{ formatDigitReadability(formatMaxFloat(rounding(calculateTotalInterest(scheduleData)))) }}</td>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
    props: {
        contractId: Number,
        frequencyOptions: Array,
        changeModeFromAddToReadOnly: Function,
        mainAgreement: Object,
        NewItem: Object,
        currentMode: String
    },

    data() {
        return {
            tableFields: [
                {
                    key: "rowNumber",
                    label: "№",
                },
                {
                    key: "leftPrincipal",
                    label: "Остаток основного долга на начало периода",
                },
                {
                    key: "year",
                    label: "Год",
                },
                {
                    key: "datePayment",
                    label: "Дата платежа",
                },
                {
                    key: "sumPayment",
                    label: "Сумма поступления",
                },
                {
                    key: "sumPaymentPrincipal",
                    label: "Сумма погашения основного долга",
                },
                {
                    key: "dateBeg",
                    label: "Начало периода начисления",
                },
                {
                    key: "dateEnd",
                    label: "Окончание периода начисления",
                },
                {
                    key: "dayCount",
                    label: "Кол-во дней",
                },
                {
                    key: "sumBase",
                    label: "Базовая сумма для начисления вознаграждения",
                },
                {
                    key: "sumPaymentInterest",
                    label: "Сумма начисленного вознаграждения",
                },
                {
                    key: "action",
                    label: "",
                },
            ],
            allAgreements: [],
            agreement: null,
            previousAgreement: null,
            previousTableFields: [],
            scheduleData: [],
            previousScheduleData: [],
            displayPreviousPlanFlag: false,
            tempScheduleData: [],
            frequency: null,
            frequencyRate: null,
            frequencyIncome: null,
            firstInflowDate: null,
            firstPrincipalDate: null,
            firstInterestDate: null,
            otherDatesMonth: null,
            otherDatesDay: null,
            tempFrequency: null,
            tempFrequencyRate: null,
            tempFrequencyIncome: null,
            tempFirstInflowDate: null,
            tempFirstPrincipalDate: null,
            tempFirstInterestDate: null,
            tempOtherDatesMonth: null,
            tempOtherDatesDay: null,
            editMode: false,
            editInterest: false,
            totalSum: 0, // TODO: replace with func calculateTotalSum everywhere
            totalPrincipalSum: 0,
            selectedAction: -1,
            
            progress: 0
        };
    },

    computed: {
        warning() {
            return !this.doesAmountAlign ? 'warning' : ''
        },

        doesAmountAlign() {
            return this.agreement?.amount === this.totalSum
        }
    },

    async created() {
        this.agreement = await this.getAgreement(this.contractId)
        this.progress = 30
        this.allAgreements = await this.getAgreements(this.contractId)
        this.progress = 50
        this.scheduleData = await this.loadScheduleData(this.contractId)
        this.setUpPreviousAgreementSchedule()
        this.setFrequenciesIfPresent(this.agreement)
        this.setInterestDateIfPresent(this.agreement)
        this.setDatesIfPresent()
        this.progress = 100
    },

    methods: {
        // #region Fetches
        async getAgreement(agreementId) {
            const response = await fetch(`/api/budget-loan/${agreementId}`)
            if (!response.ok) {
                this.makeToast('Не удалось загрузить договор (план)', 'Сообщение', 'danger')
                return
            }
            return await response.json()
        },

        async getAgreements(id) {
            const response = await fetch(`/api/budget-loan/${id}/with-additionals`)
            if (!response.ok) {
                this.makeToast('Не удалось загрузить договора (план)', 'Сообщение', 'danger')
                return
            }
            return await response.json()
        },

        async fetchPaymentSchedule(contractId) {
            const url = "api/budget-loan-schedule/payment-schedule"
            const queries = `/${contractId}`
            const response = await fetch(url + queries)
            return response
        },

        async fetchPaymentInflowSchedule(contractId, firstInflowDateString, frequencyCode) {
            const url =
                "api/budget-loan-schedule/payment-schedule-inflow/generate"
            const queries = `/${contractId}?start_inflow=${firstInflowDateString}&frequency=${frequencyCode}`
            const response = await fetch(url + queries)
            return response
        },

        async fetchPrincipalRepaymentSchedule(contractId, firstInflowDateString, frequencyCode) {
            const url =
                "api/budget-loan-schedule/payment-schedule-principal-repayment/generate"
            const queries = `/${contractId}?start_repayment=${firstInflowDateString}&frequency=${frequencyCode}`
            const response = await fetch(url + queries)
            return response
        },

        async fetchInterestPaymentSchedule(contractId, frequencyCode) {
            const url =
                "api/budget-loan-schedule/payment-schedule-interest/generate"
            const queries = `/${contractId}?frequency=${frequencyCode}`
            const response = await fetch(url + queries)
            return response
        },

        async updateInterestDateInAgreement(contractId, firstInterestDate, otherDatesMonth, otherDatesDay) {
            let otherDatesMonthParam = otherDatesMonth
            let otherDatesDayParam = otherDatesDay
            if (!otherDatesDay || !otherDatesMonth) {
                const date = new Date(firstInterestDate)
                otherDatesMonthParam = date.getMonth() + 1
                otherDatesDayParam = date.getDate()
            }
            const url =
                "api/budget-loan/update-interest"
            const queries = `/${contractId}?initial-interest-date=${firstInterestDate}&following-interest-month=${otherDatesMonthParam}&following-interest-day=${otherDatesDayParam}`
            return await fetch(url + queries, { method: 'PUT' })
        },
        // #endregion

        // #region Loads
        async generateAndLoadScheduleData(
            contractId,
            firstInflowDateString,
            firstPrincipalDate,
            firstInterestDate,
            otherDatesDay,
            otherDatesMonth
        ) {
            // 1. Сгенерировать поступления
            const inflowResponse = await this.fetchPaymentInflowSchedule(
                contractId,
                firstInflowDateString,
                this.frequencyIncome.code
            )
            if (!inflowResponse.ok) {
                this.makeToast(
                    "Не удалось подтянуть график",
                    "Ошибка",
                    "danger"
                );
                this.progress = 100
                return;
            }
            this.progress = 30

            // 2. Сгенерировать погашения
            const principalResponse = await this.fetchPrincipalRepaymentSchedule(
                contractId,
                firstPrincipalDate,
                this.frequency.code
            );
            if (!principalResponse.ok) {
                this.makeToast(
                    "Не удалось подтянуть график",
                    "Ошибка",
                    "danger"
                );
                this.progress = 100
                return;
            }
            this.progress = 60
            
            // 3. Сгенерировать вознаграждения (при ставке != 0)
            let interestItems = []
            if (this.agreement.rate !== 0) {
                const interestUpdateResponse = await this.updateInterestDateInAgreement(contractId, firstInterestDate, otherDatesMonth, otherDatesDay)
                if (!interestUpdateResponse.ok) {
                    this.makeToast(
                        "Не удалось подтянуть график",
                        "Ошибка",
                        "danger"
                    )
                    this.progress = 100
                    return
                }
                const interestResponse = await this.fetchInterestPaymentSchedule(
                        contractId,
                        this.frequencyRate.code
                    )
                if (!principalResponse.ok) {
                    this.makeToast(
                        "Не удалось подтянуть график",
                        "Ошибка",
                        "danger"
                    );
                    this.progress = 100
                    return
                }
                interestItems = await interestResponse.json();
                interestItems.map(item => {
                    item.sumPaymentInterest = item.sumPayment
                    item.sumPayment = undefined
                })
            } else {
                // Удалить вознаграждения при 0-ом проценте
                const interestType = 3
                await fetch(`api/budget-loan-schedule/payment-schedule/type/${this.contractId}?typeSchedule=${interestType}`, { method: 'DELETE' })
            }
            
            this.progress = 80
            this.scheduleData = await this.loadScheduleData(this.contractId)
            this.progress = 100
            this.makeToast(
                "Сгенерирован новый график",
                "Сообщение",
                "success"
            )
            this.$emit('reloadFact')
        },

        async loadScheduleData(contractId) {
            const response = await this.fetchPaymentSchedule(contractId)
            if (!response.ok) {
                this.makeToast("Не удалось подтянуть график", "Ошибка", "danger")
                return
            }
            const unsortedScheduleData = await response.json()

            // (1/3) fetching inflow
            const inflowItems = unsortedScheduleData.filter(item => item.typeSchedule === 1)

            this.progress = 30

            // (2/3) fetching principal repayment
            const principalItems = unsortedScheduleData.filter(item => item.typeSchedule === 2)
            principalItems.map(item => {
                item.sumPaymentPrincipal = item.sumPayment
                item.sumPayment = 0
            })

            this.progress = 60
            
            // (3/3) fetching interest
            const interestItems = unsortedScheduleData.filter(item => item.typeSchedule === 3)
            interestItems.map(item => {
                item.sumPaymentInterest = item.sumPayment
                item.sumPayment = undefined
            })

            this.progress = 90

            // combining all graphs
            let combinedItems = inflowItems
            const toAddPrincipalItems = []
            for (let i = 0; i < principalItems.length; i++) {
                const principalItem = principalItems[i]

                const sameDateItem = combinedItems.find(inflowItem => inflowItem.datePayment === principalItem.datePayment)
                if (sameDateItem) {
                    sameDateItem.sumPaymentPrincipal = principalItem.sumPaymentPrincipal
                } else {
                    toAddPrincipalItems.push(principalItem)
                }
            }
            combinedItems = combinedItems.concat(toAddPrincipalItems)

            const toAddInterestItems = []
            for (let i = 0; i < interestItems.length; i++) {
                const interestItem = interestItems[i]

                const sameDateItem = combinedItems.find(combinedItem => combinedItem.datePayment === interestItem.datePayment)
                if (sameDateItem) {
                    sameDateItem.sumPaymentInterest = interestItem.sumPaymentInterest
                    sameDateItem.dateBeg = interestItem.dateBeg
                    sameDateItem.dateEnd = interestItem.dateEnd
                    sameDateItem.dayCount = interestItem.dayCount
                    sameDateItem.sumBase = interestItem.sumBase
                } else {
                    toAddInterestItems.push(interestItem)
                }
            }
            combinedItems = combinedItems.concat(toAddInterestItems)

            combinedItems.map((item, index) => {
                // set zeros in upsent payments
                if (!item.sumPayment) {
                    item.sumPayment = 0
                }
                if (!item.sumPaymentPrincipal) {
                    item.sumPaymentPrincipal = 0
                }
                if (!item.sumPaymentInterest) {
                    item.sumPaymentInterest = 0
                }

                combinedItems = combinedItems.sort(
                    (a, b) => a.datePayment - b.datePayment
                ) // sort only once

                // calculate "Остаток основного долга на начало периода"
                if (index === 0) {
                    item.leftPrincipal = 0
                } else {
                    const previousItem = combinedItems[index-1]
                    const amount = previousItem.sumPayment - previousItem.sumPaymentPrincipal
                    item.leftPrincipal = previousItem.leftPrincipal + amount  
                }
            });

            combinedItems = combinedItems.sort(
                (a, b) => a.datePayment - b.datePayment
            );

            this.totalSum = this.calculateTotalSum(combinedItems)
            this.totalPrincipalSum = this.calculateTotalPrincipal(combinedItems)

            this.progress = 100
            return combinedItems
        },
        // #endregion

        // #region Handlers
        async handleGenerateSchedule() {
            this.progress = 10
            if (this.checkForm()) {
                await this.generateAndLoadScheduleData(this.contractId, this.firstInflowDate, this.firstPrincipalDate, this.firstInterestDate, this.otherDatesDay, this.otherDatesMonth)
            }
            this.progress = 100
            this.$emit('reloadFact')
        },

        async handleImportPreviousSchedule() {
            this.progress = 10
            const response = await this.importPreviousPlanSchedule(this.agreement.id)
            if (!response.ok) {
                this.makeToast(
                    "Не удалось импортировать данные",
                    "Сообщение",
                    "danger"
                )
                this.progress = 100
                return
            }

            this.progress = 50

            this.scheduleData = await this.loadScheduleData(this.agreement.id)
            this.setInterestDateIfPresent(this.previousAgreement)
            this.setFrequenciesIfPresent(this.previousAgreement)
            this.setDatesIfPresent()

            this.progress = 100
            this.makeToast("Импорт выполнен", "Сообщение", "success")
        },

        handleEditMode(isEnabled = null) {
            this.editInterest = false
            if (isEnabled === null) {
                this.editMode = !this.editMode
            } else {
                this.editMode = isEnabled
            }
            this.stashOrPopOriginalData()
        },

        checkSums() {
            const newSum = this.calculateTotalSum(this.scheduleData)
            const newPrincipalSum = this.calculateTotalPrincipal(this.scheduleData)

            if (this.agreement.amount !== newSum) {
                this.makeToast(
                    "Сумма платежей не совпадает суммой договора",
                    "Сообщение",
                    "danger"
                )
            }
            
            if (this.agreement.amount !== newPrincipalSum) {
                this.makeToast(
                    "Сумма платежей не совпадает суммой договора",
                    "Сообщение",
                    "danger"
                )
            }
        },

        async handleSaveEdited() {
            if (this.editInterest) {
                const payload = this.scheduleData
                    .filter(it => it.dayCount && it.dayCount !== 0)
                    .map(it => {
                        return {
                            date: this.millisToDate(it.datePayment),
                            dayCount: parseInt(it.dayCount),
                        }
                    })
                const response = await fetch(`api/budget-loan-schedule/edit-day-count/${this.contractId}`, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                if (response.ok) {
                    this.editInterest = false
                    this.editMode = false
                    this.makeToast("Редактирование применено", "Сообщение", "success")
                    this.progress = 50
                    this.scheduleData = await this.loadScheduleData(this.contractId)
                    this.progress = 100
                } else {
                    this.makeToast("Редактирование не применено", "Ошибка", "danger")
                }
                return
            }
            this.progress = 10

            this.checkSums()

            this.scheduleData = this.scheduleData.sort(
                (a, b) => a.datePayment - b.datePayment
            )

            this.editMode = false;

            const allData = [...this.scheduleData]
            const inflowData = []
            const principalData = []
            allData.forEach(item => {
                if (item.sumPayment && item.sumPayment !== 0 && item.sumPayment !== '0') {
                    inflowData.push({
                        idContract: this.contractId,
                        datePayment: item.datePayment,
                        sumPayment: item.sumPayment,
                        typeSchedule: 1
                    })
                }
                if (item.sumPaymentPrincipal && item.sumPaymentPrincipal !== 0 && item.sumPaymentPrincipal !== '0') {
                    principalData.push({
                        idContract: this.contractId,
                        datePayment: item.datePayment,
                        sumPayment: item.sumPaymentPrincipal,
                        typeSchedule: 2
                    })
                }
            })
            this.progress = 20

            // Сохранить метаданные по графику
            try {
                const response = await this.updateScheduleMetaData(
                    this.contractId,
                    this.frequency.code,
                    this.frequencyIncome.code,
                    this.frequencyRate.code,
                    this.firstInterestDate,
                    this.otherDatesMonth,
                    this.otherDatesDay
                )
                if (!response.ok) {
                    this.makeToast("Ошибка редактирование", "Сообщение", "danger")
                    this.progress = 100
                    return
                }
            } catch (error) {}

            // Сохранить поступления
            await fetch(`api/budget-loan-schedule/payment-schedule/${this.contractId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(inflowData)
            })
            this.progress = 40
            // Сохранить погашения
            await fetch(`api/budget-loan-schedule/payment-schedule/${this.contractId}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(principalData)
            })
            this.progress = 60

            // Сгенерировать вознаграждения (при ставке != 0)
            if (this.agreement.rate !== 0) {
                await this.fetchInterestPaymentSchedule(
                    this.contractId,
                    this.frequencyRate.code,
                )
            } else {
                // Удалить вознаграждения при 0-ом проценте
                const interestType = 3
                await fetch(`api/budget-loan-schedule/payment-schedule/type/${this.contractId}?typeSchedule=${interestType}`, { method: 'DELETE' })
            }
            
            this.progress = 80
            this.scheduleData = await this.loadScheduleData(this.contractId)
            this.progress = 100
            this.makeToast("Редактирование применено", "Сообщение", "success")
            this.$emit('reloadFact')
        },

        async importPreviousPlanSchedule(agreementId) {
            return await fetch(
                `api/budget-loan-schedule/import-previous-plan-schedule/${agreementId}`,
                { method: 'PUT' }
            )
        },

        async updateScheduleMetaData(
            id,
            frequency,
            frequencyIncome,
            frequencyRate,
            initialInterestDate,
            followingInterestMonth,
            followingInterestDay,
        ) {
            const params = {}
            params['frequency'] = frequency
            params['frequency-income'] = frequencyIncome
            params['frequency-rate'] = frequencyRate
            params['initial-interest-date'] = this.millisToDate(initialInterestDate)
            params['following-interest-month'] = followingInterestMonth
            params['following-interest-day'] = followingInterestDay
            const requestParams = new URLSearchParams(params)
            return await fetch(
                `api/budget-loan/update-schedule-meta-data/${id}?${requestParams}`,
                { method: 'PUT' }
            ) 
        },

        handleInputDate(key, value) {
            if (!value.startsWith("0")) {
                if (key === 'firstInflowDate') {
                    this.firstInflowDate = value
                } else if (key === 'firstPrincipalDate') {
                    this.firstPrincipalDate = value
                } else if (key === 'firstInterestDate') {
                    this.firstInterestDate = value
                }
            } // if allow year to start with 0, would reset input
        },

        handleAddRow(id, toAbove) {
            const data = [...this.scheduleData]
            for (let i = 0; i < data.length; i++) {
                const item = data[i]
                if (data[i].id === id) {
                    let id
                    do {
                        id = this.uuidv4()                        
                    } while (data.findIndex(item => item.id === id) !== -1)    
                    let position = i                 
                    if (!toAbove) {
                        position += 1
                    }
                    data.splice(position, 0, {
                        id: id,
                        idContract: this.contractId,
                        datePayment: undefined,
                        sumPayment: 0,
                    })
                    break
                }
            }
            this.scheduleData = data
        },

        handleRemoveRow(id) {
            this.scheduleData = this.scheduleData.filter(item => item.id !== id)
        },

        handleFieldChange(fieldName, value, index) {
            if (["datePayment", 'dateBeg', 'dateEnd'].includes(fieldName)) {
                if (!value.startsWith('0')) {
                    this.scheduleData[index][fieldName] = value
                } // if allow year to start with 0, would reset input
            } else {
                this.scheduleData[index][fieldName] = value;
            }
        },

        handleToggleAction(id) {
            if (this.selectedAction === id) {
                this.selectedAction = -1
                return
            }
            this.selectedAction = id
        },
        // #endregion

        // #region Utils
        handleDateFormat(value) {
            let v = value
            if (value.indexOf('-') >= 5) {
                const year = value.slice(0, 4)
                const rest = value.slice(-5)
                v = year + '-' + rest
            }
            return v
        },

        onEditInterestCheck(value) {
            this.scheduleData = this.deepCopy(this.tempScheduleData)
            this.frequency = this.deepCopy(this.tempFrequency)
            this.frequencyRate = this.deepCopy(this.tempFrequencyRate)
            this.frequencyIncome = this.deepCopy(this.tempFrequencyIncome)
            this.firstInflowDate = this.deepCopy(this.tempFirstInflowDate)
            this.frequencyRate = this.deepCopy(this.tempFrequencyRate)
            this.firstPrincipalDate = this.deepCopy(this.tempFirstPrincipalDate) 
            this.firstInterestDate = this.deepCopy(this.tempFirstInterestDate) 
            this.otherDatesMonth = this.deepCopy(this.tempOtherDatesMonth)
            this.otherDatesDay = this.deepCopy(this.tempOtherDatesDay)
        },

        // millisToDate(millis) {
        //     if (!millis) {
        //         return "";
        //     }
        //     const date = new Date(millis);
        //     const year = date.getFullYear();
        //     let month = date.getMonth() + 1;
        //     if (month.toString().length === 1) {
        //         month = "0" + month;
        //     }
        //     let day = date.getDate();
        //     if (day.toString().length === 1) {
        //         day = "0" + day;
        //     }
        //     return year + "-" + month + "-" + day;
        // },
        millisToDate(millis) {
            if (!millis) {
                return '';
            }
            const date = new Date(millis);

            // Check if time is 23:00:00 and add an hour if it is
            if (date.getHours() === 23 && date.getMinutes() === 0 && date.getSeconds() === 0) {
                date.setTime(date.getTime() + 3600000); // Adds 1 hour (3600000 milliseconds)
            }

            const year = date.getFullYear();
            let month = date.getMonth() + 1;
            month = month < 10 ? '0' + month : month.toString();
            
            let day = date.getDate();
            day = day < 10 ? '0' + day : day.toString();

            return year + '-' + month + '-' + day;
        },


        millisToYear(millis) {
            if (!millis) {
                return "";
            }
            const date = new Date(millis);
            const year = date.getFullYear();

            return year;
        },

        formatDigitReadability(number) {
            number += "";
            const removedSpaceString = number.replace(/\s/g, "");
            number = removedSpaceString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            return number;
        },

        formatMaxFloat(value) {
            const maximumFloatLength = 2
            value += ''
            value = value.replace(/[^\d,. -]/g, "")
            let until = value.length
            if (value.indexOf(',') !== -1) {
                until = value.indexOf(',') + maximumFloatLength + 1
            } else if (value.indexOf('.') !== -1) {
                until = value.indexOf('.') + maximumFloatLength + 1
            }
            value = value.substring(0, until)
            return value
        },

        rounding(value) {
            value += ''
            value = value.replace(/[^\d,. -]/g, "")
            let thirdIndex
            if (value.indexOf(',') !== -1) {
                thirdIndex = value.indexOf(',') + 3
            } else if (value.indexOf('.') !== -1) {
                thirdIndex = value.indexOf('.') + 3
            }
            if (thirdIndex) {
                const thirdNumber = parseInt(value[thirdIndex])
                if (thirdNumber >= 5 && thirdNumber <= 9) {
                    value = parseFloat(value) + 0.01
                }
            }

            return value
        },

        makeToast(message, title, variant) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true,
            });
        },

        deepCopy(object) {
            return JSON.parse(JSON.stringify(object));
        },

        uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (character) {
                const random = Math.random() * 16 | 0;
                const v = character == 'x' ? random : (random & 0x3 | 0x8);
                return v.toString(16);
            })
        },
        // #endregion

        stashOrPopOriginalData() {
            if (this.editMode) {
                this.tempScheduleData = this.deepCopy(this.scheduleData)
                this.tempFrequency = this.deepCopy(this.frequency)
                this.tempFrequencyRate = this.deepCopy(this.frequencyRate)
                this.tempFrequencyIncome = this.deepCopy(this.frequencyIncome)
                this.tempFirstInflowDate = this.deepCopy(this.firstInflowDate)
                this.tempFrequencyRate = this.deepCopy(this.frequencyRate)
                this.tempFirstPrincipalDate = this.firstPrincipalDate
                this.tempFirstInterestDate = this.firstInterestDate
                this.tempOtherDatesMonth = this.otherDatesMonth
                this.tempOtherDatesDay = this.otherDatesDay
            } else {
                this.scheduleData = this.tempScheduleData
                this.frequency = this.tempFrequency
                this.frequencyRate = this.tempFrequencyRate
                this.frequencyIncome = this.tempFrequencyIncome
                this.firstInflowDate = this.tempFirstInflowDate
                this.frequencyRate = this.tempFrequencyRate
                this.firstPrincipalDate = this.tempFirstPrincipalDate 
                this.firstInterestDate = this.tempFirstInterestDate 
                this.otherDatesMonth = this.tempOtherDatesMonth
                this.otherDatesDay = this.tempOtherDatesDay
            }
        },

        openAdditionalsModal(action = null) {
            this.$emit('openAdditionalsModal', action)
        },

        calculateTotalSum(scheduleItems) {
            let sum = 0
            scheduleItems.forEach((item) => {
                const parsedSum = parseFloat(item.sumPayment)
                if (parsedSum) {
                    sum += parsedSum
                }
            })
            return sum
        },

        calculateTotalPrincipal(scheduleItems) {
            let sum = 0
            scheduleItems.forEach((item) => {
                const parsedSum = parseFloat(item.sumPaymentPrincipal)
                if (parsedSum) {
                    sum += parsedSum
                }
            });
            return sum
        },

        calculateTotalInterest(scheduleItems) {
            let sum = 0
            scheduleItems.forEach((item) => {
                const parsedSum = parseFloat(item.sumPaymentInterest)
                if (parsedSum) {
                    sum += parsedSum
                }
            })
            return sum
        },

        checkForm() {
            let validated = true

            if (!this.firstInflowDate) {
                this.makeToast(
                    "Укажите дату первого поступления",
                    "Сообщение",
                    "danger"
                )
                validated = false
            }
            if (!this.firstPrincipalDate) {
                this.makeToast(
                    "Укажите дату первого погашения",
                    "Сообщение",
                    "danger"
                )
                validated = false
            }
            if (!this.frequencyIncome) {
                this.makeToast(
                    "Укажите периодичность поступления",
                    "Сообщение",
                    "danger"
                )
                validated = false
            }
            if (!this.frequency) {
                this.makeToast(
                    "Укажите периодичность погашения основного долга",
                    "Сообщение",
                    "danger"
                )
                validated = false
            }
            if (!this.frequencyRate) {
                this.makeToast(
                    "Укажите периодичность погашения вознаграждения",
                    "Сообщение",
                    "danger"
                )
                validated = false
            }
            if (!this.firstInterestDate) {
                this.makeToast(
                    "Укажите начальную дату погашения вознаграждения",
                    "Сообщение",
                    "danger"
                )
                validated = false 
            }
            if (this.otherDatesMonth || this.otherDatesDay) {
                if (this.otherDatesMonth < 0 || this.otherDatesMonth > 12) {
                    this.makeToast(
                        "Последующая дата погашения вознаграждения: месяц должен быть между 1 и 12",
                        "Сообщение",
                        "danger"
                    )
                    validated = false 
                } else if (this.otherDatesDay < 0 || this.otherDatesDay > 28) {
                    this.makeToast(
                        "Последующая дата погашения вознаграждения: день должен быть между 1 и 28",
                        "Сообщение",
                        "danger"
                    )
                    validated = false 
                }
            }

            const mainBegDate = (new Date(this.mainAgreement.dateContract)).getTime()
            const endTime = (new Date(this.agreement.contractDateEnd)).getTime()
            if (this.firstInflowDate) {
                const inflowTime = (new Date(this.firstInflowDate)).getTime()
                if (inflowTime < mainBegDate) {
                    this.makeToast(
                        "Дата поступления не может быть раньше даты подписания договора",
                        "Сообщение",
                        "danger"
                    )
                    validated = false
                } else if (inflowTime > endTime) {
                    this.makeToast(
                        "Дата поступления не может быть позже даты возврата",
                        "Сообщение",
                        "danger"
                    )
                    validated = false
                }
            }
            if (this.firstPrincipalDate) {
                const prinicpalTime = (new Date(this.firstPrincipalDate)).getTime()
                if (prinicpalTime < mainBegDate) {
                    this.makeToast(
                        "Дата погашения не может быть раньше даты подписания договора",
                        "Сообщение",
                        "danger"
                    )
                    validated = false
                } else if (prinicpalTime > endTime) {
                    this.makeToast(
                        "Дата погашения не может быть позже даты возврата",
                        "Сообщение",
                        "danger"
                    )
                    validated = false
                }
            }
            if (this.firstInterestDate) {
                const interestTime = (new Date(this.firstInterestDate)).getTime()
                if (interestTime < mainBegDate) {
                    this.makeToast(
                        "Дата погашения вознаграждения не может быть раньше даты подписания договора",
                        "Сообщение",
                        "danger"
                    )
                    validated = false
                } else if (interestTime > endTime) {
                    this.makeToast(
                        "Дата погашения вознаграждения не может быть позже даты возврата",
                        "Сообщение",
                        "danger"
                    )
                    validated = false
                }
            }

            return validated
        },

        setFrequenciesIfPresent(agreement) {
            const agreementCopy = {...agreement} 
            if (this.agreement.frequency) {
                const frequency = this.frequencyOptions.find(it => it.code === agreementCopy.frequency)
                this.frequency = { ...frequency }
            }
            if (this.agreement.frequencyRate) {
                const frequencyRate = this.frequencyOptions.find(it => it.code === agreementCopy.frequencyRate)
                this.frequencyRate = { ...frequencyRate }
            }
            if (this.agreement.frequencyIncome) {
                const frequencyIncome = this.frequencyOptions.find(it => it.code === agreementCopy.frequencyIncome)
                this.frequencyIncome = { ...frequencyIncome }
            }
        },

        setInterestDateIfPresent(agreement) {
            this.firstInterestDate = this.millisToDate(agreement.initialInterestRepaymentDate)
            this.otherDatesMonth = agreement.followingInterestRepaymentMonth
            this.otherDatesDay = agreement.followingInterestRepaymentDay
        },

        async setUpPreviousAgreementSchedule() {
            this.previousTableFields = this.tableFields.map(field => {
                const coloredField = {...field}
                coloredField.variant = 'primary'
                return coloredField
            })
            const copiedAdditionals = this.allAgreements.map((item, index) => {
                const copy = JSON.parse(JSON.stringify(item))
                copy.order = index
                return copy
            })
            const currentAgreementIndex = copiedAdditionals
                .findIndex(item => this.agreement.id === item.id)
            const previousAgreement = copiedAdditionals[currentAgreementIndex-1]
            if (previousAgreement) { 
                this.previousAgreement = previousAgreement
                const tempPreviousScheduleData = await this.loadScheduleData(previousAgreement.id)
                this.previousScheduleData = tempPreviousScheduleData
            }
        },

        setDatesIfPresent() {
            const firstIncome = this.scheduleData.find(item => item.sumPayment)
            if (firstIncome) {
                this.firstInflowDate = this.millisToDate(firstIncome.datePayment)
            }

            const firstRepayment = this.scheduleData.find(item => item.sumPaymentPrincipal)
            if (firstRepayment) {
                this.firstPrincipalDate = this.millisToDate(firstRepayment.datePayment) 
            }
        },

        goBack() {
            this.$router.back()
        }
    },
};
</script>

<style lang="scss" scoped>
.plan {
    background-color: #b8daff!important;
}

.parameter-title {
    font-size: 0.7777777778rem;
    color: #5C7F96;
    font-weight: 500;
    margin: 0;
    padding: 0 0 0 2px;
    line-height: 1;
}

.parameter-item {
    flex: 1;
    padding: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #dee2e6; //dee2e6 c6c6c6
}

.sticky-bottom-row {
    position: sticky;
    z-index: 100;
    background-color: #F7F9FC;
    bottom: 0;
}

.bold {
    font-weight: bold;
}

.warning {
    background-color: #FDE9E2;
}

    .no-wrap-space {
        white-space: nowrap;
    }

    .error,
.more-info {
    position: relative;

    &.on-left {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    &.on-right {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-left: 10px;
    }

    input {
        padding: 0 10px 0 25px;
        color: red;
    }

    .pop-up {
        position: absolute;
        top: -150px;
        width: 240px;
        padding: 15px;
        background-color: #FFF9EB;
        border-radius: 5px;
        box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.07);
        opacity: 0;
        z-index: 1010;
        visibility: hidden;
        transition: 0.2s all ease;

        &:before {
            content: '';
            position: absolute;
            top: -8px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #FFF9EB transparent;
        }

        p {
            font-size: 12px;
            font-weight: 600;
        }

        .red-text {
            color: red;
        }

        table {
            border: none;
            width: 100%;

            tr {
                td {
                    padding: 10px;
                    border-left: none !important;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    &:last-child {
                        border-right: none !important;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }

    .pop-up-reverse {
        position: absolute;
        top: 35px;
        right: -1000px;
        width: 240px;
        padding: 15px;
        background-color: #ffffff;
        border-radius: 5px;
        box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.07);
        opacity: 0;
        z-index: 1010;
        visibility: hidden;
        transition: 0.2s all ease;

        &:before {
            content: '';
            position: absolute;
            top: -8px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 7px 7px;
            border-color: transparent transparent #FFF9EB transparent;
        }

        .red-text {
            color: red;
        }

        table {
            border: none;
            width: 100%;

            tr {
                td {
                    padding: 10px;
                    border-left: none !important;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);

                    &:last-child {
                        border-right: none !important;
                    }
                }

                &:last-child {
                    td {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }

    .hold-on-hover {
        &:hover {
            opacity: 1;
            visibility: visible;
        }
    }

    i {
        position: absolute;
        top: 0;
        left: 0;
        color: red;

        &:hover {
            &+.pop-up {
                opacity: 1;
                visibility: visible;
            }

            &+.pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .show-on-hover {
        &:hover {
            &+.pop-up {
                opacity: 1;
                visibility: visible;
            }
            &+.pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &.text-only {
        &>span {
            color: #212529;
            text-decoration: underline;
        }

        &:hover {
            .pop-up {
                opacity: 1;
                visibility: visible;
            }

            .pop-up-reverse {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &:not(.more-info-right) {
        .pop-up {
            left: 0;

            &:before {
                left: 5px;
            }
        }

        .pop-up-reverse {
            left: 0;

            &:before {
                left: 5px;
            }
        }
    }

    &.more-info-right {
        .pop-up {
            right: 0;

            &:before {
                right: 5px;
            }
        }

        .pop-up-reverse {
            right: 0;

            &:before {
                right: 5px;
            }
        }
    }
}
</style>
