







































































































































































import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BudgetLoanForecastForm } from '@/modules/budget/execution/loan/types/BudgetLoanForecastForm'
import { millisToDate, millisToYear, deepCopy, rounding, formatDigitReadability, formatMaxFloat, formatNumberCombined } from '@/modules/budget/execution/loan/utils'
import { Vue, Component, Prop } from 'vue-property-decorator';

interface ScheduleRow {
  year: number;
  leftPrincipal?: number;
  paymentDate: number;
  inflowSum?: number;
  repaymentSum?: number;
  refundSum?: number;
  unusedSum?: number;
  dateBeg?: number;
  dateEnd?: number;
  daysCount?: number;
  sumBase?: number;
  interestSum?: number;
  interestFactSum?: number;
  copaymentSum?: number;
}

interface EditDayCount {
    date: string;
    dayCount: number
}

@Component
export default class CPaymentScheduleForecastTab extends Vue {
    // #region Props
    @Prop({
        type: Number,
        required: false,
        default: null,
    })
    public readonly agreementId!: number | null;
    @Prop({
        type: Object,
        required: true
    })
    public readonly mainAgreement: any;
    @Prop({
        type: Object,
        required: true
    })
    public readonly NewItem: any;
    @Prop({
        type: String,
        required: true
    })
    public readonly currentMode!: string;
    // #endregion

    // #region Data
    public scheduleData: ScheduleRow[] = [];
    public tempScheduleData: ScheduleRow[] = [];
    public progress = 100;
    public editMode = false;
    public readonly tableFields = [
        {
            key: "rowNumber",
            label: "№",
        },
        {
            key: "leftPrincipal",
            label: "Остаток основного долга на начало периода",
        },
        {
            key: "year",
            label: "Год",
        },
        {
            key: "paymentDate",
            label: "Дата платежа",
        },
        {
            key: "inflowSum",
            label: "Cумма поступления",
        },
        {
            key: "repaymentSum",
            label: "Сумма погашения основного долга",
        },
        {
            key: "refundSum",
            label: "Сумма досрочного погашения",
        },
        {
            key: "unusedSum",
            label: "Сумма возврата неиспользованных средств",
        },
        {
            key: "dateBeg",
            label: "Начало периода начисления",
        },
        {
            key: "dateEnd",
            label: "Окончание периода начисления",
        },
        {
            key: "daysCount",
            label: "Кол-во дней",
        },
        {
            key: "sumBase",
            label: "Базовая сумма для начисления вознаграждения",
        },
        {
            key: "interestSum",
            label: "Сумма выплаты вознаграждения",
        },
        {
            key: "interestFactSum",
            label: "Сумма выплаты вознаграждения (факт)",
        },
        {
            key: "copaymentSum",
            label: "Сумма сопутствующих платежей",
        }
    ]
    // #endregion

    // #region LifeCycle
    private async created() {
        // Construct schedule data
        await this.constructForecastSchedule()
    }
    // #endregion

    // #region Methods
    public async generateNewForecastSchedule() {
        this.progress = 30
        try {
            const response = await fetch(`/api/budget-loan-schedule/payment-schedule-forecast/generate/${this.agreementId}`, {
                method: 'POST'
            })
            const data: BudgetLoanForecastForm[] = await response.json()
            this.progress = 80
            this.scheduleData = this.mapBudgetLoanForecastFormToSchedule(data)
            this.makeToast('Сгенерировано', 'Сообщение', 'success')
        } catch (error) {
            this.makeToast('Не удалось сгенерировать график', 'Ошибка', 'danger')
        } finally {
            this.progress = 100
        }
    }

    public async constructForecastSchedule() {
        this.progress = 30
        try {
            const response = await fetch(`/api/budget-loan-schedule/payment-schedule-forecast/get-data/${this.agreementId}`)
            const data: BudgetLoanForecastForm[] = await response.json()
            this.progress = 80
            this.scheduleData = this.mapBudgetLoanForecastFormToSchedule(data)
        } catch (error) {
            
        } finally {
            this.progress = 100
        }
    }

    public async getForecastScheduleWithDayCountCorrection(dayCountCorrections: EditDayCount[]) {
        this.progress = 30
        try {
            const response = await fetch(`/api/budget-loan-schedule/payment-schedule-forecast/get-data-with-correct/${this.agreementId}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dayCountCorrections)
            })
            const data: BudgetLoanForecastForm[] = await response.json()
            this.progress = 80
            this.scheduleData = this.mapBudgetLoanForecastFormToSchedule(data)
            this.makeToast('Редактирование применено', 'Сообщение', 'success')
        } catch (error) {
            this.makeToast('Не удалось загрузить прогнозные данные', 'Ошибка', 'danger')
        } finally {
            this.progress = 100
        }
    }

    private mapBudgetLoanForecastFormToSchedule(data: BudgetLoanForecastForm[]) {
        return data
            .sort((a, b) => a.scheduleDate - b.scheduleDate)
            .map(item => {
                const copaymentSum = (item.sumPenaltyPrincipal??0) + (item.sumPenaltyInterest??0)
                const row: ScheduleRow = {
                    year: this.millisToYear(item.scheduleDate),
                    leftPrincipal: item.principalBalance,
                    paymentDate: item.scheduleDate,
                    inflowSum: item.sumInflow,
                    repaymentSum: item.sumPrincipalRepaymentForecast,
                    refundSum: item.sumRefund,
                    unusedSum: item.sumRepaymentUnused,
                    dateBeg: item.dateBeg,
                    dateEnd: item.dateEnd,
                    daysCount: item.dayCount,
                    sumBase: item.sumBase,
                    interestSum: item.sumInterestForecast,
                    interestFactSum: item.sumInterestFact,
                    copaymentSum: copaymentSum
                }
                return row
            })
    }

    public async handleSaveEdited() {
        const edited: EditDayCount[] = this.scheduleData
            .filter(item => item.daysCount == 0 || item.daysCount)
            .map(item => {
                return {
                    date: this.millisToDate(item.paymentDate), // @ts-ignore
                    dayCount: parseInt(item.daysCount!!)
                }
            })
        const initial: EditDayCount[] = this.tempScheduleData
            .filter(item => item.daysCount == 0 || item.daysCount)
            .map(item => {
                return {
                    date: this.millisToDate(item.paymentDate), // @ts-ignore
                    dayCount: parseInt(item.daysCount!!)
                }
            })

        const payload: EditDayCount[] = []
        initial.forEach(initialItem => {
            const correspondingEditedItem = edited.find(editedItem => editedItem.date === initialItem.date)
            if (correspondingEditedItem && correspondingEditedItem?.dayCount !== initialItem.dayCount) {
                payload.push({
                    date: correspondingEditedItem.date,
                    dayCount: correspondingEditedItem.dayCount
                })
            }
        })
        if (payload.length > 0) {
            await this.getForecastScheduleWithDayCountCorrection(payload)
        }
        this.tempScheduleData = []
        this.editMode = false
    }

    public handleDayCountChange(value: number, index: number) {
        this.scheduleData[index]['daysCount'] = value
    }

    private makeToast(message: string, title: string, variant: 'success' | 'danger') {
        this.$bvToast.toast(message, {
            title: title,
            variant: variant,
            solid: true,
        })
    }

    public calculateTotal(scheduleData: ScheduleRow[], column: keyof ScheduleRow) {
        let total = 0
        scheduleData.forEach((item) => {
            if (item[column]) {
                total += (item[column]??0)
            }
        })
        return total
    }

    openAdditionalsModal(action: string | null = null) {
        this.$emit('openAdditionalsModal', action)
    }

    goBack() {
        this.$router.back()
    }

    toggleEditMode(isEnabled: boolean | null = null) {
        if (isEnabled === null) {
            this.editMode = !this.editMode
        } else {
            this.editMode = isEnabled
        }
        
        if (this.editMode) {
            this.tempScheduleData = this.deepCopy(this.scheduleData)
        } else {
            this.scheduleData = this.tempScheduleData
        }
    }
    // #endregion

    // #region Import declarations
    millisToDate = millisToDate
    millisToYear = millisToYear
    rounding = rounding
    formatDigitReadability = formatDigitReadability
    formatMaxFloat = formatMaxFloat
    formatNumberCombined = formatNumberCombined
    deepCopy = deepCopy
    // #endregion
}
